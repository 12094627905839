.filedrop {
  width: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 6px;
  transition: background-color 0.2s ease-in;
  background-color: #b2ebf2;
  border: 1px dashed #546f72;
  touch-action: none;
  cursor: pointer;
}

.filelist {
  padding: 0;
  list-style-type: none;
}

.uploadIcon {
  width: 48px;
  height: 48px;
  margin-right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.uploadLabel {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.error {
  position: relative;
  font-size: 12px;
  line-height: 12px;
  color: #f44336;
}
