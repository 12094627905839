.account-navigation-button {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: solid 1px var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 16px;
    color: var(--grey);
    transition: color 0.2s;
  }

  > :first-child {
    font-size: 20px;
    position: relative;
  }

  &:hover > span {
    color: var(--soft-grey);
  }
}
