.item {
  padding: 0;
  margin: 0;
}

.item-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.link {
  color: var(--soft-grey);
  text-decoration: none;
  padding: 12px 18px;
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;

  &:hover {
    color: var(--white);
  }
}

.linkActive {
  cursor: pointer;
  color: var(--seafoam) !important;
}

.submenu {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: none;

  .item:last-child {
    border-bottom: none;
  }
}

.submenuExpanded {
  display: block;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text-icon {
  display: block;
  width: 40px;
  margin: 0 auto 8px;
  font-size: 16px;
}

.grants .link {
  color: var(--white);
  padding: 20px 24px;

  .text {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
  }

  .text-icon {
    font-size: 24px;
    width: 30px;
    margin: 0;
    margin-right: 8px;
    margin-bottom: -4px; /* icon is off :( */
  }
}

.annotation {
  color: #ffde8d;
  margin-left: 6px;
  margin-top: 2px;
  font-weight: 600;
  font-size: 8px;
  position: absolute;
}

.gold-text {
  color: #ffde8d;
}

.gold-pro {
  color: #262a2d;
  background-color: #ffde8d;
  font-family: averta, Arial, sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 16px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 0px;
  margin-top: 4px;
  border-radius: 2px;
  min-width: fit-content;
  height: 16px;
  width: 30px;
  margin: 4px auto 0 auto;
}

@media screen and (max-height: 780px) {
  .link,
  .grants .link {
    .text {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .text-icon {
      width: auto;
      flex-grow: 0;
      margin: 0;
      margin-right: 8px;
    }
  }
}
