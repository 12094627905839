.statItem {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--soft-purple);

  position: relative;
  padding-right: 22px;

  &:after {
    content: '';
    width: 2px;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    border-radius: 50%;
    margin: 0 10px;
    background-color: var(--soft-purple);
  }

  &:last-of-type {
    padding-right: 0;

    &:after {
      display: none;
    }
  }
}
