.option {
  margin-bottom: 16px;
}

.actions {
  height: 44px;
  flex-grow: 0;
  color: var(--mid-grey);
}

.alignToEnd .actions {
  align-self: flex-end;
  margin-bottom: 13px;
}
