.inputs-wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.inputs-wrapper-title-first {
  margin-top: 5px;
}

.user-org-inputs-subtitle {
  margin-bottom: 10px;
}

.user-org-inputs-divider {
  margin: 20px 0;
}

.input-dropzone {
  min-width: 130px;
  max-width: 130px;
  min-height: 130px;
  max-height: 130px;
  margin-right: 24px;
}

.input-name {
  flex: 1 0 auto;
}

.input-half-width {
  max-width: 50%;
}

.container-divided-equally {
  justify-content: space-between;

  > div {
    flex: 1;
  }

  > div:first-of-type {
    margin-right: 15px;
  }

  > div:last-of-type {
    margin-left: 15px;
  }
}
