.project-switcher {
  cursor: pointer;
  position: relative;

  > div {
    height: 100%;
    font-size: 12.5px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      opacity: 1;
      font-size: 16px;
      transition: transform 0.2s;
      transform: rotate(0deg);
    }
  }
}
