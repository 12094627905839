.headerSteps {
  height: 48px;
  flex-grow: 0;
  background-color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  column-gap: 24px;
}

.step {
  height: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: row;
  align-items: stretch;

  a {
    display: block;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #737e88;
    border-bottom: 4px solid transparent;
    display: flex;
    align-items: center;

    > :last-child {
      margin-left: 8px;
    }
  }

  &:not(:last-child):after {
    content: '›';
    margin-left: 14px;
    font-size: 20px;
    font-weight: 600;
    transform: scaleX(1.5);
    display: flex;
    align-items: center;
    color: #737e88;
    border-bottom: 8px solid transparent;
  }
}

.stepNoSeparator {
  &:after {
    display: none !important;
  }
}

.stepActive {
  a {
    border-bottom-color: #52cccc;
    color: #52cccc;
  }
}

.stepDisabled {
  cursor: disabled;
  a {
    color: #737e88;
    opacity: 0.4;
    border-bottom-color: transparent;
  }
}
