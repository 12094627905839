.reviewItem {
  padding-top: 8px;
  margin-bottom: 20px;
  font-size: 14px;
}

.buttonAlert {
  text-transform: uppercase;
  font-weight: bold !important;
  margin-top: 18px;

  &:hover {
    text-decoration: none !important;
  }
}

.formExtraInfo {
  margin-top: 48px;
}
