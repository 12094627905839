.progressBar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: #e3e9ee;
  margin-bottom: 16px;
}

.progressBarFill {
  height: 10px;
  border-radius: 10px;
  background: linear-gradient(
    to left,
    var(--ocean),
    var(--green),
    var(--ocean),
    var(--green)
  );
  background-size: 300% 100%;
  animation: pulse 1s linear infinite;
}

.progressBarIcon {
  margin-left: -24px;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    background-position: 100% 0%;
  }
  25% {
    background-position: 75% 0%;
  }
  50% {
    background-position: 50% 0%;
  }
  75% {
    background-position: 25% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
