.cardWrapper {
  padding: 20px;
}

.chartConfigWrapper {
  padding-bottom: 20px;
}

.chartTitleButtonWrapper {
  float: right;
  margin-top: -5px;
}

.sidebarFlexColumn {
  height: 100%;
}
