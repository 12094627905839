.dateDropdownInputs {
  > div + div {
    margin-left: 8px;
  }
}

.dateDropdownSelectMini {
  min-width: 84px;
}

.dateDropdownSelectMax {
  min-width: 98px;
}
