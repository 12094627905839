.crmListItem {
  margin: 0 0 16px;
  padding: 8px;
  background: #fff;
  border-radius: 6px;
}

.crmListItemActive {
  background: transparent;
}

.customRegionMappingList {
  list-style: none;
  padding: 0 0 5px;
  margin: 0;
}

.crmListItemTitle {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: #221e20;
  margin: 0 0 16px;
}

.crmListItemContent {
  font-size: 14px;
  line-height: 1.4;
}

.spaceBottom {
  padding-bottom: 20px;
}

.iconClickable {
  cursor: pointer;
}

.buttonsSeparated {
  button + button {
    margin-left: 15px;
  }
}
