.header {
  color: var(--seafoam);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  .title {
    color: inherit;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 0.86;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }
  > :first-child {
    align-content: flex-start;
    > * {
      margin-right: 8px;
    }
  }
}

.questionPreviewWrapper {
  padding: 0 20px 20px 20px;
  margin-bottom: 4px;
  border-radius: 6px;
  background: #fff;
  transition: opacity 0.2s ease-in-out;
  overflow: hidden;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.07);

  > :first-child > :first-child {
    margin-top: 0;
  }

  > :first-child:last-child {
    margin: 0;
    border-bottom: 0;
    pointer-events: none;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 100%;
    cursor: pointer;
    z-index: 10;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
  }

  &:hover {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &.isLayout {
    background: transparent;
    box-shadow: none;
    padding: 20px 0;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.35;

  &:hover:after {
    display: none;
  }
}

.selected {
  pointer-events: none;
  border: 1px solid #52cccc;

  &:hover:after {
    content: '';
    background: transparent;
  }
}
