.surveyBuilder {
  padding-left: 100px !important;
  padding-right: 100px !important;
  max-width: 1200px;
}

.surveyName {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 0.5px;
  text-align: left;
  color: #262a2d;
  color: var(--dark);
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 24px;

  > * {
    margin-left: 10px;
    font-size: 16px;
    color: var(--mid-grey);
  }
}

.surveyBuilderWarning {
  margin-bottom: 40px;
}
