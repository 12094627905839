.iconSubItem {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-left: 1px solid var(--mid-grey);
  border-bottom: 1px solid var(--mid-grey);
  flex: 0 0 auto;
  margin: 3px 8px 0 0;
  align-self: flex-start;
}
