.questionItem {
  position: relative;
}

.surveyElementsList {
  > * {
    padding-bottom: 24px;
  }
}

.questionMover {
  color: var(--soft-purple);
  position: absolute;
  z-index: 1;
  right: 100%;
  padding-right: 16px;
  padding-top: 16px;
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 100px;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;

  > :first-child {
    margin-bottom: 8px;
  }

  .quesitonMoverActions {
    margin-right: 6px;
  }

  .moverAddQuestion,
  .quesitonMoverActions span {
    opacity: 0;
    transition: opacity 0.1s;
  }

  .quesitonMoverActions span {
    font-size: 0.8em;
    padding: 2px;
  }

  .questionMoverForm {
    display: flex;
    align-items: center;
    margin: 0;
    margin: -8px -8px 0 0;

    input {
      height: 32px !important;
      padding: 0 8px !important;
      text-align: right;
    }
  }

  .questionMoveConfirm {
    color: var(--green);
    font-size: 1em;
    padding: 8px;
    float: right;
  }
}

.questionItem:hover .questionMover {
  .quesitonMoverActions span {
    opacity: 1;
  }

  .moverAddQuestion {
    opacity: 1;
  }
}

.addQuestion {
  z-index: 2;
  width: 100%;
  border-bottom: 1px dashed var(--seafoam);
  position: absolute;
  bottom: 8px;
  opacity: 0;
  transition: opacity 0.15s;

  > button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--seafoam) !important;
    right: 100%;
    position: absolute;
    margin-right: 8px;
    margin-top: -12px;
    font-size: 16px !important;
    color: var(--seafoam) !important;
    padding: 0;
    line-height: 23px;
    background: transparent;
    cursor: pointer;
    outline: 0;
  }
}

.questionMover + .addQuestion {
  bottom: auto;
  top: -8px;
}

.questionItem:hover .addQuestion {
  opacity: 1;
}

.questionSectionPageMarker {
  text-align: center;
  margin-bottom: 16px;

  > div {
    color: var(--dark-blue);
    background-color: var(--pale-grey);
    display: inline-block;
    position: relative;
    padding: 0 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  &:before {
    content: ' ';
    display: block;
    border-bottom: 1px solid var(--mid-grey);
    position: relative;
    bottom: -12px;
  }
}

.statusIndicators {
  position: absolute;
  left: 100%;
  margin: 24px 0;
  top: 0;

  > * {
    margin-bottom: 4px;
  }
}
