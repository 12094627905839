.analysis-type {
  flex: 1 0 50%;
  margin: 20px 15px 0;
  position: relative;
}

.analysis-type-icon {
  margin-top: 90px !important;
}

.analysis-type-title-active {
  text-align: left;
  margin-top: 0;
  margin-bottom: 30px;
}

.analysis-type-card-inactive {
  padding-bottom: 120px;
}

.analysis-type-card-active,
.analysis-type-card-inactive {
  flex: 1;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.analysis-type-card-single-inactive {
  opacity: 0.5;
}

.analysis-type-actions {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.analysis-type-close {
  height: 27px !important;
  width: 27px !important;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
