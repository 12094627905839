.header,
.subheader,
.text,
.sectionDescription {
  white-space: pre-wrap;
}

.header,
.subheader,
.sectionTitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: var(--dark);

  @media (--tablet) {
    font-size: 24px;
  }
}

@media print {
  .header,
  .subheader,
  .sectionTitle {
    font-size: 16pt;
    page-break-after: avoid;
  }

  .layoutElementSection {
    page-break-inside: avoid;
  }
}

.subheader {
  font-weight: 300;
}

.sectionTitle {
  margin-top: 32px !important;
  font-weight: normal;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--mid-grey);
}

.sectionTitle + .sectionDescription {
  margin-top: 16px;
  color: var(--soft-purple);
}

.sectionHidden {
  text-align: center;
  color: var(--soft-purple);
  &:before {
    content: ' ';
    display: block;
    border-bottom: 1px dashed var(--soft-purple);
    position: relative;
    bottom: -10px;
  }
}

.sectionHiddenTitle {
  background-color: var(--pale-grey);
  display: inline-block;
  position: relative;
  padding: 0 16px;
}

.text {
  font-size: 12px;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: var(--dark);

  @media (--tablet) {
    font-size: 14px;
  }
}

@media print {
  .text {
    font-size: 12pt;
  }
}
