.taggingSuggestionEditorContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.taggingSuggestionEditorActions {
  display: flex;
  gap: 12px;
}

.reportPreviewContainer {
  padding: 8px;
  border-radius: var(--default-radius);
  border: 1px solid var(--black);
  background: #ffffff60;
}

.reportPreviewContainer > label {
  padding-bottom: 8px;
}

.editor {
  font-size: 14px;
  height: 100%;
  line-height: 1.75;
}

.qlEditorOverride {
  height: calc(100vh - 600px) !important;
  min-height: 300px;
}

.suggestedTaggingHeader {
  display: flex;
  gap: 8px;
}

.suggestedTaggingContainer {
  padding: 20px;
  border-radius: var(--default-radius);
  border: 1px solid var(--grey);
  background: var(--white);
  font-size: 16px;
  line-height: 24px;
  font-family: averta, Arial, sans-serif !important;
}

.suggestedTaggingTypeDropdown {
  min-width: 200px;
}

.suggestedTaggingSelectedText {
  font-size: 14px;
  line-height: 24px;
  font-family: averta, Arial, sans-serif !important;
  padding: 20px;
  color: var(--dark);
}
