.taggingTypeBlock {
  border-radius: 0 6px 6px 0;
}

.isClickable {
  cursor: pointer;
}

.taggingTypeExtra {
  > div:first-child {
    cursor: default;
    padding-right: 8px;
    position: relative;
  }

  > div:first-child:after {
    content: '';
    width: 1px;
    position: absolute;
    top: -3px;
    bottom: -3px;
    right: 0;
    background: var(--grey);
  }
}
