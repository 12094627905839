.mappingItem {
  flex-wrap: nowrap;
}

.mappingItemSkipped {
  color: #7c91a2;
}

.mappingItemUnmapped {
  color: var(--red);
}

.mappingItemIcon {
  line-height: 1;
}

.mappingItemIconClickable {
  cursor: pointer;
}

.mappingItemToRight {
  text-align: right;
}
