.tagRowTagTitle,
.tagRowTagging {
  margin: 2px 0 0;
  align-self: flex-start;
  min-height: 24px;
}

.tagRowTagTitle {
  max-width: 300px;
  font-size: 11px;
  max-height: 24px;
}
.tagRowTagContainerLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 11px;
  letter-spacing: -0.25px;
}
.tagRowTagContainer {
  margin: 0;
  gap: 4px;
}
.tagRowTagGroupList {
  padding: 0;
  margin: 0;
}
.tagRowTagRootList {
  padding: 4px;
  margin: 0;
  gap: 4px;
}
