.userSettingsWrapper {
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.userSettingsTitle {
  margin-top: 0;
}
