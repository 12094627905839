.grantAddContainer {
  justify-content: center;

  h3 {
    margin-top: 0;
  }
}

.grantAddSection {
  align-items: center;
}

.grantAddCentered {
  width: 100%;
  max-width: 768px;
  min-width: 520px;
}

.dividerPadded {
  margin: 30px 0;
}

.spaceLeft {
  margin-left: 5px;
}

.spaceBottom {
  padding-bottom: 30px;
}

.marginBottom {
  margin-bottom: 30px;
}

.titleCustomMargin {
  margin: 0 0 30px;
}

.actionIcon {
  cursor: pointer;

  & + .actionIcon {
    margin-left: 15px;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &[disabled]:hover {
    fill: #000000de !important;
  }
}

.listItem {
  margin-bottom: 10px;
  align-items: center;

  &:last-of-type {
    padding-bottom: 30px;
  }
}
