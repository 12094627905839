.full-bg-view-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

.full-bg-view-logo {
  max-width: 196px;
  margin-bottom: 50px;
}

.light-box {
  min-width: 400px;
  border-radius: 6px;
  background-color: rgba(244, 246, 249, 0.85);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
  padding: 25px;
}

.light-box-limited-width {
  max-width: 500px;
}

.light-box-title {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: #262a2d;
  margin: 0 0 30px;
}

.light-box-actions {
  padding-top: 10px;
}

.light-box-info {
  max-height: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.7px;
  color: #6b748f;
  white-space: pre-line;
  opacity: 0;
  transition:
    max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}

.light-box-info-displayed {
  max-height: 50px;
  opacity: 1;
}

.light-box-error {
  color: #f82020;
  white-space: pre-line;
}
