.tagGroupList {
  width: 100%;
  list-style: none;
  padding: 12px 6px;
  margin: 0;
  overflow: auto;
  position: relative;
}

.tagsListPendingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  pointer-events: none;
}
