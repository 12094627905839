@import url(./TextField.module.css);

.imui-datepicker {
  input {
    font-family: averta, Arial, sans-serif !important;
  }

  &.imui-date-picker-flatWhite {
    input {
      background-color: var(--white) !important;
    }
  }
  &.imui-text-field-flatDark {
    input {
      padding: 10px 10px !important;
      margin-top: 0 !important;
      margin-bottom: 0px !important;
      background-color: var(--greyish) !important;
    }
  }

  > div:first-of-type {
    height: 42px !important;
    width: 100% !important;
  }

  &.imui-datepicker-has-hint {
    > div > div:first-of-type {
      bottom: auto !important;
      top: 9px;
      left: 20px;
      font-weight: 300;
      font-size: 14px;
      z-index: 1;
      pointer-events: none;
      font-family: averta, Arial, sans-serif !important;
    }
  }

  &.imui-text-field-disabled {
    > div > div > hr {
      border-bottom: 0px !important;
    }

    > div > input {
      color: rgb(80, 80, 80) !important;
    }
  }
}
