.logo {
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    display: block;
    height: 27px;
    width: auto;
    padding-right: 2px;
  }
}
