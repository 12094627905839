.imui-toggle {
  overflow-x: hidden;

  label {
    font-family: averta, Arial, sans-serif !important;
    text-align: left !important;
    line-height: 28px !important;
    font-size: 13px;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    color: #262a2d !important;
  }

  /* wrapper */
  > div {
    /* toggle wrapper */
    > div {
      padding: 0 0 2px 2px !important;
      width: 51px !important;
      height: 31px !important;
      /* tracker */
      > div:first-child {
        border: 1px solid #e3e9ee;
        width: 100% !important;
        height: 100% !important;
      }
      /* thumb */
      > div:last-child {
        top: 2px !important;
        margin-left: 5px !important;
        width: 24px !important;
        height: 24px !important;

        span {
          display: none;
        }
      }
    }
  }

  &.imui-toggle-label-left {
    label {
      text-align: right !important;
    }
  }

  &.imui-toggle-active {
    > div > div > div:last-child {
      margin-left: -27px !important;
    }
  }

  &.imuiToggleMini {
    > div > div {
      transform: scale(0.5713);
      margin-top: -2px;
      left: -13px;
      margin-bottom: -2px;
      height: 28px !important;

      > div:last-child {
        width: 21px !important;
        height: 21px !important;
      }
    }

    &.imui-toggle-active {
      > div > div > div:last-child {
        margin-left: -25px !important;
      }
    }

    label {
      line-height: 24px !important;
      margin-left: -24px;
    }
  }
}

.imui-toggle-compact {
  width: auto !important;
}
