.wrapper {
  max-width: 600px;
}

.dictLabel {
  font-size: 14px !important;
}

.dictTitle {
  margin: 0 0 20px;

  > small {
    display: inline-block;
    font-size: 14px;
    margin-left: 7px;
  }
}

.spaceBottom {
  margin-bottom: 5px;
}
