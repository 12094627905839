.imui-tile-spacing-m {
  margin: -15px;
  place-content: center;
  > * {
    padding: 15px;
    place-content: center;
  }
}

.imui-tile-horizontal-centered {
  justify-content: center;
}

.imui-tile-vertical-centered {
  align-items: center;
}
