.wrapper {
  float: right;
  margin-right: 20px;
  margin-top: -5px;
}
.chart-drawer-class {
  padding: 20px;
}
.chart-drawer {
  opacity: 0.99;
}
.halfSelect {
  width: 50% !important;
}
