.field-item-icons {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 5px 15px 0;
}

.field-item-icon-disabled {
  pointer-events: none;
  fill: #93aabd !important;
}

.field-item-icon {
  cursor: pointer;
}

.field-item-icon-counter {
  font-size: 14px;
}

.field-item-space {
  margin: 0 0 15px 5px;
}
