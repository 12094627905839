.quotesWrapper {
  padding: 4px;
  flex-shrink: 0;
  border-top: 0;
  flex-wrap: nowrap;
}
.quotesContent {
  word-wrap: break-word;
  white-space: normal;
}
.quotesTableRow {
  border-bottom: 20px solid #f4f6f9;
}
