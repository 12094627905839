@import './UserOrgInputs.module.css';

.inputs-wrapper {
  max-width: 910px;
}

.space-left {
  margin-left: 20px;
}

.membership-management-header {
  align-items: flex-start;
  justify-content: space-between;

  > button {
    margin-left: 20px;
  }
}
