.imui-section {
  /*position: relative;*/
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom: 1px solid #e3e9ee;
  flex-shrink: 0;
  transition: background-color 0.3s;

  &:last-child {
    border-bottom: none;
  }

  @media print {
    display: block;
  }
}

.imui-section-content {
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  /* Firefox quirk */
  min-height: 0;

  @media (--tablet) {
    padding: 24px 40px;
  }
}

.imui-section-footer {
  background: #f4f6f9;
  padding: 6px 40px;
  min-height: 50px;
  margin-bottom: -40px;
  border-top: 1px solid #cccccc;
  @media (--tablet) {
    min-height: 64px;
    max-height: 64px;
    padding: 6px 40px;
  }
  @media print {
    display: none;
  }
}

.imui-section-sticky-footer {
  position: fixed;
  z-index: 10;
  border-top: 1px solid #e3e9ee;
  border-bottom: 1px solid #e3e9ee;
  margin-top: -1px;
  margin-bottom: 0;
  left: 110px;
  right: 0;
  bottom: 0;
  background: #f4f6f9;
  padding: 6px 40px;
  min-height: 50px;
  @media (--tablet) {
    min-height: 64px;
    max-height: 64px;
    padding: 6px 40px;
  }
  @media print {
    display: none;
  }
}

.imui-section-sticky-footer-survey {
  position: fixed;
  z-index: 11;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin-top: -1px;
  margin-bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f4f6f9;
  padding: 6px 40px;
  min-height: 50px;
  @media (--tablet) {
    bottom: 0;
    min-height: 64px;
    max-height: 64px;
    padding: 6px 40px;
  }
  @media print {
    display: none;
  }
}

.imui-section-sticky-footer-second {
  position: fixed;
  z-index: 10;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin-top: -1px;
  margin-bottom: 0;
  left: 110px;
  right: 0;
  bottom: 50px;
  background: #f4f6f9;
  padding: 6px 40px;
  min-height: 50px;
  @media (--tablet) {
    bottom: 64px;
    min-height: 64px;
    max-height: 64px;
    padding: 6px 40px;
  }
  @media print {
    display: none;
  }
}

.imui-section-header,
.imui-section-footer,
.imui-section-sub-header,
.imui-section-context-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.imui-section-header {
  min-height: 79px;

  h1,
  h2,
  h3 {
    margin: 0;
  }

  @media print {
    display: none;
  }
}

.imui-section-sub-header {
  padding: 16px;
  @media (--tablet) {
    padding: 20px 40px;
  }
  h1,
  h2,
  h3 {
    margin: 0;
  }
}
.imui-section-collapsed {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
  + .imui-section {
    margin-top: 0;
    padding-top: 8px;
  }
}

.imui-section-actions {
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30px;
  padding-bottom: 31px;
}

.imui-section-context-header {
  height: 50px;
}

.imui-section-context-header,
.imui-section-sunken {
  background-color: #e3e9ee;
}

.imui-section-sunken {
  flex-direction: column;
  padding: 20px 40px;
}

.imui-section-grow {
  flex-grow: 1;
}

.imui-section-secondary {
  background-color: #6b748f;
  color: #fff;
}

.imui-section-horizontal {
  flex-direction: row;
  flex-wrap: wrap;
}

.imui-section-no-border {
  border-bottom: none;
}

.imui-section-no-padding {
  padding: 0;
}

.imui-section-centered {
  align-items: center;
  justify-self: center;
}

.imui-section-scrollable {
  width: 100%;
  position: relative;
  overflow: auto;
  border-bottom: none;
}

.imui-section-full-height {
  flex: 1;
}

.imui-section-limited-width {
  min-width: 640px;
  max-width: 960px;
  margin: 0 auto;
}

.imui-section-survey-width {
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
}

.imui-section-survey-width:not(.imui-section-mobile-styling) {
  @media (--tablet) {
    min-width: 640px;
  }
}

.imui-section:not(.imui-section-mobile-styling) {
  @media (--mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
  @media (--tablet) {
    padding-right: 24px;
    padding-left: 24px;
  }
  @media (--desktop) {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media print {
  .imui-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
