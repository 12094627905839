.tagChoice {
  font-size: 16px;
  max-width: 22px;
  min-width: 22px !important;
}
.tagChoice > div > span {
  font-size: 16px;
  line-height: 32px;
}
.tagSelectorMini {
  display: block;
  margin-top: 3px;
  margin-right: 2px;
  margin-left: 4px;
  .tagSelector-add {
    overflow: visible;
  }
}
.tagSelectorMini .tagSelector-tag-div {
  margin-top: 6px;
}
.tagSelector {
  font-size: 12px;

  .tagSelectorWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;

    > div {
      gap: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}

.tagIcon {
  font-size: 16px;
  line-height: 24px;
  color: var(--mid-grey);
}
.tagSelector-add {
  min-height: 20.5px;
  max-height: 20.5px;
  overflow: hidden;
  align-self: baseline;
  min-width: 66px;
  cursor: pointer;
  align-self: flex-start;
}
.tagSelector-add > div {
  min-height: 20.5px;
  max-height: 20.5px;
  line-height: 18px;
  font-size: 12px;
}
.tagSelector-tag {
  min-height: 21px;
  max-height: 21px;
  overflow: hidden;
  align-self: baseline;
  min-width: 66px;
  max-width: 200px;
  align-self: flex-start;
  &:last-child {
    margin-right: 0;
  }
}
.tagSelector-tag-div {
  display: contents;
}
.tagSelector-tag-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 11px;
  letter-spacing: -0.25px;
}
.tagSelector-label {
  font-size: 16px;
}

.tagSelector-popover-empty {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: #7c91a2;
  text-align: center;
  padding: 20px;
}

.tagSelectorTextFieldProgress {
  padding: 0;
  display: inline-block;
  top: -31px;
}
