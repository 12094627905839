.imui-dialog-overlay {
  background-color: rgba(39, 46, 66, 0.6) !important;
}

.imui-dialog {
  border-radius: 6px !important;
  background-color: #f4f6f9 !important;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07) !important;
  max-width: 640px !important;
  width: 100%;

  > div {
    background: transparent !important;
    color: #262a2d !important;
  }

  @media (--tablet) {
    width: 55% !important;
    min-width: 360px !important;
  }

  &.imui-dialog-small {
    max-width: 540px !important;

    @media (--tablet) {
      width: 40% !important;
      min-width: 260px !important;
    }
  }

  &.imui-dialog-large {
    max-width: 800px !important;

    @media (--tablet) {
      width: 80% !important;
      min-width: 460px !important;
    }
  }

  &.imui-dialog-extra-large {
    max-width: 1045px !important;

    @media (--tablet) {
      width: 80% !important;
      min-width: 460px !important;
    }
  }

  &.imui-dialog-light {
    background-color: var(--white) !important;
  }
}

.imui-dialog-title-container {
  font-size: 24px !important;
  font-weight: 300 !important;
  line-height: 1.17 !important;
  letter-spacing: 0.5px !important;
  color: #262a2d !important;

  > [class^='icon'] {
    font-size: 20px;
    margin-right: 0;
  }

  &.imui-dialog-title-container-raw {
    padding: 30px 30px 16px !important;
  }

  &:not(.imui-dialog-title-container-raw) {
    padding: 30px 30px 0px !important;
  }
}

.imui-dialog-title-container-raw + .imui-dialog-body-container {
  padding-top: 4px !important;

  @media (--tablet) {
    padding-top: 14px !important;
  }
}

.imui-dialog-body-container {
  padding: 20px !important;
  overflow: auto !important;
  position: relative;
  line-height: 1.4;

  @media (--tablet) {
    padding: 30px !important;
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    color: #221e20;
  }

  > * {
    margin: 0 0 15px !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.imui-dialog-body-container-show-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    border: 2px solid #f4f6f9;

    &:active {
      background: rgba(0, 0, 0, 0.61);
    }
  }
}

.imui-dialog-empty-body .imui-dialog-body-container {
  padding: 0 0 30px !important;
}

.imui-dialog-force-top {
  div:has(> div > &) {
    padding-top: 0 !important;
  }
}

.imui-dialog-actions-container {
  padding: 15px 24px !important;
  border-top: 1px solid #e3e9ee;

  > div {
    display: flex;
  }
}

@media print {
  .imui-dialog-overlay {
    display: none !important;
  }

  .imui-dialog {
    display: none !important;
  }
}
