.importUploads {
  margin: 0;
}

.importUploadsTitle {
  margin-bottom: 10px;
}

.importUploadsItemIcon {
  font-size: 24px;
  display: inline-block;
  padding: 5px;
  position: relative;
  top: 3px;

  &:hover {
    color: var(--red);
  }
}

.sectionContent {
  flex-grow: 1;
}

.title {
  margin: 0;
}

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.limitedWidth {
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
}
