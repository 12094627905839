.answersRestrictionSelectWrapper {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-top: 4px;
  margin-left: -4px;
  margin-right: -4px;

  & > * {
    margin: 0 4px;
  }
}
