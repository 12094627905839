.financial-question {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.financial-question-input {
  display: inline-block;
  margin-right: 10px;
  flex: 1;
}
