.imui-popover {
  border-radius: 6px !important;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.14) !important;
  height: auto;
  overflow: hidden !important;
  padding: 0px !important;
  background-color: #fff !important;

  &.imui-popover-custom-width {
    width: 100%;
  }

  &:not(.imui-popover-custom-width) {
    min-width: 250px;
    max-width: 300px;
    max-height: 600px;
  }

  &.imui-popover-fixed-width {
    min-width: 300px !important;
  }
  &.imui-popover-absolute-left {
    min-width: 300px !important;
    left: 110px !important;
    right: unset !important;
    top: 0 !important;
    bottom: 0 !important;
    .imui-popover-content {
      max-height: unset;
    }
  }
  &.imui-popover-absolute-right {
    min-width: 300px !important;
    right: 0 !important;
    left: unset !important;
    top: 0 !important;
    bottom: 0 !important;
    .imui-popover-content {
      max-height: unset;
    }
  }
  > div,
  > div > div {
    display: flex;
    flex-direction: column;
    max-height: inherit !important;
    height: 100%;
    flex: 1;
  }

  &.imui-popover-dark {
    .imui-popover-header {
      background: #f4f6f9;
    }

    .imui-popover-content {
      background: #f4f6f9;
    }
  }

  &.imui-popover-compact {
  }

  &.imui-popover-padded {
    .imui-popover-header,
    .imui-popover-content,
    .imui-popover-footer {
      padding: 20px;
    }

    .imui-popover-header + .imui-popover-content {
      padding: 10px 20px 20px;
      margin-top: -10px;
    }
  }

  &.imui-popover-large {
    min-height: 400px;
    height: 100%;
    min-width: 350px !important;
    max-width: 450px !important;

    &.imui-popover-fixed-width {
      min-width: 450px !important;
    }
  }

  &.imui-popover-small {
    min-width: 150px !important;
    max-width: 200px !important;

    &.imui-popover-fixed-width {
      min-width: 200px !important;
    }
  }
}

.imui-popover-header {
  flex-grow: 0;
  padding: 10px 16px;
}

.imui-popover-content {
  overflow: auto;
  max-height: 600px;
  flex-grow: 1;

  div[role='menu'] {
    padding-bottom: 0px !important;
  }
}

.imui-popover-footer {
  flex-grow: 0;
}

.imui-popover-footer-action {
  display: block;
  cursor: pointer;
  border-color: #e3e9ee;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  width: 100%;
  padding: 15px;
  text-align: left;
  font-family: averta, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  min-height: 0;
  background-color: #ffffff;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.listItemSimple {
  padding: 4px 4px;
  margin: 0 12px;

  &.clickable {
    cursor: pointer;

    [class*='readonly'] {
      cursor: pointer !important;
    }

    &:hover {
      background-color: #00000005;
      border-radius: 6px;
    }
  }

  input + div > div {
    opacity: 0.3;
  }

  input:checked + div > div {
    opacity: 1;
  }
}
