.imui-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: auto;
  position: relative;
  padding-bottom: 40px;
}

.imui-page-noPadding {
  padding-bottom: 0;
}

@media print {
  .imui-page {
    /* Content being cut off with auto */
    /* overflow: scroll !important; */
    padding-bottom: 0;
    display: block !important;
  }
}
@media print {
  .header-actions {
    display: none;
  }
}
.header-actions {
  flex-grow: 0;
}
