.user-orgs-list-item-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .user-orgs-list-icon {
      transform: rotate(45deg);
    }
  }
}

.user-orgs-list-item {
  font-weight: 300;
  font-size: 24px;
  color: #262a2d;
  line-height: 1.25;

  &:hover {
    .user-orgs-list-item-action {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.user-orgs-list-item-current {
  font-weight: bold;
}

.user-orgs-list-item-mark {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #7c90a1;
  margin-left: 10px;
}

.user-orgs-list-item-mark-pending-approval {
  color: #e0961d;
}

.user-orgs-list-item-mark-pending-deletion {
  color: #f82020;
}

.user-orgs-list-item-icon {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  color: #7c90a1;
  margin-left: 10px;

  transition: all 0.3s ease-in-out;
}

.user-orgs-list-item-action {
  margin-left: 10px;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.2s ease-in-out;
}
