.sub-header-actions {
  flex: 0;
  flex-wrap: nowrap;
  align-items: center;
}

.user-orgs-tab-icon {
  font-size: 16px;
}

.user-orgs-sub-header-centered {
  justify-content: center !important;
}
