.wrapper {
  font-size: 12px;

  @media (--tablet) {
    font-size: 14px;
  }
}

.question {
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 600;
}

.questionTitle {
  margin-top: 0px;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
  font-size: 16px;

  @media (--tablet) {
    font-size: 24px;
  }
}

.questionHasConditions {
  margin-bottom: 8px;
}

.questionConditionIndicator {
  display: flex;
  margin-bottom: 20px;
  color: var(--dark);
  letter-spacing: 0.5px;
}

.questionTitleAsterix {
  align-self: flex-start;
  font-size: 12px !important;
  line-height: 1;
  margin-left: 8px;
}

@media print {
  .questionTitle {
    font-size: 12pt;
  }
}

.questionTitleError {
  color: var(--red);
}

.description {
  display: block;
  white-space: pre-line;
  margin: 0 0 20px;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  color: var(--mid-grey);
  white-space: pre-line;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e9ee;
}

@media print {
  .description {
    margin: 0 0 12px;
    padding-bottom: 12px;
    color: var(--mid-grey);
  }
}

.questionUntitled {
  font-style: italic;
}

.required {
  margin-right: 8px;
  font-weight: 300;
  font-style: normal;
}

.error {
  padding: 0;
  color: var(--red);
  margin: 0 0 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.errorIcon {
  margin-right: 8px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -2px 0px -2px -5px;

  > * {
    margin: 2px 0px 2px 5px;
  }
}

.addTagButton {
  height: 28px !important;
  line-height: 28px !important;
  margin: 0 5px 5px 0 !important;

  span {
    padding-left: 12px !important;
    font-size: 12px !important;
    font-weight: 300 !important;
  }

  svg {
    width: 18px !important;
    height: 18px !important;
    margin-right: 8px !important;
  }
}

.tagMenuItem {
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 32px !important;
  min-height: 32px !important;
}

.tagGroupMenuItem {
  font-size: 0.8em !important;
  min-height: 0 !important;
  line-height: 1.4 !important;
  padding: 5px 16px !important;
  text-transform: uppercase;
  white-space: normal !important;
}
