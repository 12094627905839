.devicePreviewContainer {
  max-width: 100%;
  width: 100%;
  border: 2px solid #c5d2d9;
  border-radius: 6px;
  margin: 0 auto;
  transition: max-width 0.5s ease-in-out 0.2s;
}

.devicePreviewContainerSmall {
  max-width: 350px;
}

.devicePreviewContainerPanel {
  display: flex;
  padding: 36px 16px 16px;
  align-items: center;
  border-bottom: 2px solid #c5d2d9;
  position: relative;

  transition: padding 0.2s ease-in-out;
}

.devicePreviewContainerPanelSmall {
  padding-top: 16px;
}

.devicePreviewContainerBody {
  display: flex;
  justify-content: center;
}

.devicePreviewDots {
  position: absolute;
  top: 4px;
  left: 11px;

  > span {
    width: 9px;
    height: 9px;
    display: inline-block;
    background-color: #c5d2d9;
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  span + span {
    margin-left: 7px;
  }
}

.devicePreviewTriangles {
  color: #c5d1d9;
}

.devicePreviewDotsHidden > span {
  opacity: 0;
}

.devicePreviewTriangles {
  margin-right: 18px;
}

.devicePreviewBar {
  display: inline-block;
  height: 27px;
  flex: 1;
  border: solid 2px #c5d1d9;
}

.devicePreviewMenu {
  margin-left: 18px;
  color: #c5d1d9;
  top: 0;
  right: 0;
  transition:
    top,
    right 0.2s ease-in-out;
}

.devicePreviewMenuTop {
  position: absolute;
  top: 10px;
  right: 16px;
}

@media print {
  .devicePreviewContainerBody {
    display: block;
  }
  .devicePreviewContainer {
    border: none !important;
  }
  .devicePreviewContainerPanel,
  .devicePreviewContainerPanelSmall {
    display: none;
  }
}
