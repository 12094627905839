.imui-stepped-progress {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 10px;
  position: relative;
}

.imui-stepped-progress-step {
  position: relative;
  top: 0;
  flex: 1 1 0;
  transition: all 50ms 100ms ease-in;
  height: 10px;
  min-height: 10px;
  background-color: #f0f2f5;
  margin: 10px 0;
  &:first-of-type {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-of-type {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 0;
  }
}

.imui-stepped-progress-hover-enabled:hover .imui-stepped-progress-step {
  top: -20px;
}

.imui-stepped-progress-step-active {
  background-color: #14cf6d;
  &.tertile-1 {
    opacity: 0.2;
  }
  &.tertile-2 {
    opacity: 0.4;
  }
  &.tertile-3 {
    opacity: 1;
  }
}

.imui-stepped-progress-details {
  opacity: 0;
  position: absolute;
  bottom: -20px;
  transition: all 150ms 100ms ease-in;
  font-size: 8px;
  pointer-events: none;
  text-align: center;
  white-space: nowrap;
}

.imui-stepped-progress-hover-enabled:hover .imui-stepped-progress-details {
  opacity: 1;
  bottom: 0;
  pointer-events: all;
}

.imui-stepped-no-margins .imui-stepped-progress-step {
  margin: 0;
}
