.mappingItemSelectorRadios {
  padding-bottom: 16px;

  input + div {
    align-items: center;

    > div,
    svg {
      width: 18px !important;
      height: 18px !important;
    }

    > div {
      margin-right: 8px !important;
      margin-top: -6px;
    }

    svg:first-of-type {
      fill: var(--mid-grey) !important;
    }
  }

  label {
    width: auto !important;
    color: var(--dark) !important;
    font-family: averta, Arial, sans-serif !important;
  }
}

.mappingItemSelectorTick {
  font-size: 16px;
}
