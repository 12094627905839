.imui-tabs-container {
  flex: 0 0 auto;
  &.imui-tabs-container-limited {
    flex: 1;
  }
}

.imui-tabs-header-limited {
  margin: -20px !important;
  flex: 0 0 auto;
  max-height: 48px;
}
.imui-tabs {
  display: flex;
  height: 100%;
  column-gap: 28px;
  height: 48px;
}
.imui-tab {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  outline: 0;
  border: none;
  background-color: inherit;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: #79d8d8;

    span {
      color: #79d8d8;
    }
  }

  span {
    color: #7c91a2;
    text-transform: capitalize;
    font-size: 13px;
    letter-spacing: 0.4px;
    line-height: 16px;
    font-weight: 600;
    font-family: averta, Arial, sans-serif;
    transition: color 0.3s ease-in-out;
    white-space: nowrap;
  }
}

.imui-tab-active {
  border-color: #52cccc;

  &:hover {
    border-color: #52cccc;

    span {
      color: #52cccc;
    }
  }

  span {
    color: #52cccc;
  }
}

.imui-tabs-context-header {
  z-index: 1;
  margin: 0;
}

.imui-tabs-content-limited {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.imui-tabs-content-space-top {
  padding: 40px 0;
}
