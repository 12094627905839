.editorArea {
  position: relative;
  flex: 1 1 auto;
  flex-basis: 100%;
  transition: opacity 0.3s;
}
.editorArea *::selection {
  background: var(--highlight-color, #c1fa8b);
}

.aiTaggingContainer {
  position: relative;
  background-image: linear-gradient(to right, var(--seafoam), var(--green));
}

.aiTaggingContainerSelected {
  /* From
   * src/pages/App/Analysis/TagEditor/components/coloringStrategy.js
   * const FOCUSED_COLOR = '#FFF59D';
  */
  background: #fff59d;
}

.aiTaggingContainer:hover {
  cursor: pointer;
}

.aiTaggingContainer:hover:before {
  content: 'Recommended';
  background-image: linear-gradient(to right, var(--sky), var(--green));
  color: white;
  position: absolute;
  text-wrap: nowrap;
  margin-top: -20px;
  margin-left: -2px;
  padding: 2px;
}

.isPending {
  opacity: 0.5;
}

.editorWrapper {
  padding-right: 10px;
  height: 100%;
  position: relative;
  background-color: transparent;
  border-radius: 6px;
}

.editor {
  font-size: 13px;
  height: 100%;
}

@media (--tablet) {
  .tagEditorCardWrapper {
    gap: 20px;
    padding-top: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 10px;
  }
}

.tagEditorCardWrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
}

.tagEditorCard {
  height: 100%;
  background-color: transparent;
  position: relative;
}

.tagsInContext {
  background-color: var(--pale-grey);
  border-top: 2px solid var(--light-grey);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex-shrink: 0;
  padding: 6px;
  max-height: auto;
  overflow: scroll;
  label {
    float: left;
    font-size: 11px;
    margin-right: 4px;
    margin-bottom: 0px;
    color: var(--mid-grey);
    line-height: 1;
  }
  small {
    font-size: 11px;
    color: var(--dark-grey);
    display: inline-block;
    overflow-y: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: pre-line;
    font-style: italic;
    line-height: 1;
  }
  > div > div > div {
    text-align: left !important;
  }
}

.isHidden {
  display: none;
}
.tagEditorFooter {
  margin: 0;
  padding: 4px;
  height: 46px;
  min-height: 46px;
  max-height: 46px;
  overflow: hidden;
  border-top: 1px solid var(--grey);
  background-color: var(--pale-grey);
}

/* These styles are critical for taggings to be properly applied */
.reportTagging :global(.ql-clipboard) {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}

.reportTagging :global(.ql-container) {
  box-sizing: border-box;
  font-size: 13px;
  line-height: 1.3;
  height: 100%;
  margin: 0px;
  position: relative;
  width: auto;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 0px;
}
.reportTagging :global(.ql-right .ql-editor > p) {
  white-space: normal;
  text-align-last: right;
  word-wrap: break-word;
}
.reportTagging :global(.ql-right .ql-editor > div) {
  white-space: pre-wrap;
  text-align-last: right;
  word-wrap: break-word;
  margin: 0 auto 0 auto;
}
.reportTagging :global(.ql-left .ql-editor > p) {
  white-space: normal;
  text-align-last: left;
  word-wrap: break-word;
}
.reportTagging :global(.ql-left .ql-editor > div) {
  white-space: pre-wrap;
  text-align-last: left;
  word-wrap: break-word;
  margin: 0 auto 0 auto;
}
.reportTagging :global(.ql-pdf .ql-editor > p) {
  white-space: pre;
  text-align-last: justify;
  word-wrap: break-word;
}
.reportTagging :global(.ql-pdf .ql-editor > div) {
  white-space: pre;
}
.reportTagging :global(.ql-pdf .ql-editor > p > strong:first-child) {
  display: table;
  text-align-last: left;
}
.reportTagging :global(.ql-pdf .ql-editor > div > strong:first-child) {
  display: table;
  text-align-last: left;
}
.reportTagging :global(pre .ql-editor) {
  font-family: Menlo, Lucida, Monaco, monospace;
  font-size: 13px;
} /* should have fixed glyph  */
.reportTagging :global(pre.ql-default .ql-editor) {
  font-family: averta, Arial, sans-serif;
  line-height: 1.5;
}

.reportTagging :global(.ql-editor div + h4) {
  margin-top: 32px;
}
.reportTagging :global(.ql-editor > div:first-child) {
  font-weight: 600;
  font-size: 18px;
}
.reportTagging :global(.ql-editor > div) {
  white-space: pre-wrap;
  text-align-last: left;
}
.reportTagging :global(.ql-editor h4) {
  font-weight: 600;
  margin: 8px 0;
}
.reportTagging :global(.ql-editor > *) {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
.reportTagging :global(.ql-editor) {
  max-width: max(800px, calc(100% - 80px));
  margin: 0 auto;
  margin-block: 0;
  margin-bottom: 15px;
  border-radius: 6px;
  background-color: var(--white);
  width: 100%;

  overflow-y: auto;
  height: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  cursor: text;
  outline: none;
  tab-size: 2;
  text-align: left;
  white-space: pre-wrap;
  /* white-space: normal; */
  word-wrap: break-word;

  > p:first-child {
    font-size: 18px;
    font-weight: 600;
    white-space: normal;
    text-align: center;
    text-align-last: center;
  }
}
