.spinner {
  padding: 50px;
  text-align: center;
}

.charts {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

.chart {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dededa;
  padding: 12px 0;
  align-items: center;
}

.chartName {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  cursor: pointer;
}

.chartIconCell {
  text-align: center !important;

  > svg {
    max-width: 30px;
  }
}

.chartAction {
  font-size: 16px;
}

.chartAction + .chartAction {
  margin-left: 14px;
}

.isDraft {
  font-style: italic;
}
