.projects {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 0 0 -30px;

  &:after {
    content: '';
    width: 370px;
  }
}

.projectLink {
  flex: 0 0 33.33333%;
  text-decoration: none;
  outline: 0;

  @media (max-width: 1024px) {
    flex-basis: 50%;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }

  @media (min-width: 1450px) {
    flex-basis: 25%;
  }

  @media (min-width: 2200px) {
    flex-basis: 20%;
  }
}

.project {
  height: 280px;
  flex-shrink: 1;
  margin: 0 0 30px 30px;
  text-align: center;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 140%;
  transition: all 0.3s ease-in !important;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:before {
    content: ' ';
    position: absolute;
    z-index: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(79, 91, 119, 0),
      rgba(39, 46, 66, 0.3) 70%,
      rgba(39, 46, 66, 0.8)
    );
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &:hover {
    background-size: auto 160%;
    .editProjectLink {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

@media screen and (max-height: 690px) {
  .project {
    height: 200px;
  }
}

.projectText {
  position: relative;
  padding: 10px;
  z-index: 0;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}

.editProjectLink {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 20px;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.2s ease-in;
}

.textField {
  max-width: 50%;
  padding-left: 15px;
}
