.tree {
  margin: 0;
  padding: 0;
  position: relative;

  &:not(:empty):before {
    content: '';
    height: 100%;
    width: 1px;
    display: inline-block;
    position: absolute;
    left: 8px;
    top: 0;
    background-image: linear-gradient(
      90deg,
      var(--grey),
      var(--grey) 75%,
      transparent 75%,
      transparent 100%
    );
    background-size: 8px 5px;
  }
}
/* distance on treeNodeSeparator */
.tree li ul.tree {
  padding-top: 24px;
}
/* distance between list items */
.tree li + li:first-child {
  margin-top: 24px;
}
.tree li + li {
  margin-top: 16px;
}
.tree li + li:last-child {
  margin-bottom: 16px;
}

.treeContainer {
  position: relative;
}

.treePendingOverlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  padding-top: 50px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
