.drawerDetailsTitle {
  color: var(--soft-purple);
  max-width: 100%;

  h3 {
    color: var(--dark);
    margin: 0;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.5px;
  }

  span {
    margin-right: 8px;
  }
}

.drawerDetailsSubtitleItem {
  color: var(--soft-purple);
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.drawerDetailsSubtitleItemIcon {
  max-width: 16px;
  width: 100%;
  text-align: center;
  margin-right: 8px;

  > * {
    margin-right: 0;
  }
}

.drawerDetailsList {
  list-style: none;
  padding: 0;
}

.drawerDetailsSectionTitle {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.71;
  letter-spacing: 1px;
  margin: 24px 0 8px;

  span[role='img'] {
    font-size: 18px;
    color: var(--mid-grey) !important;
    margin: 0 8px;
  }
}

.drawerDetailsItem {
  color: var(--dark-blue);
  font-size: 14px;

  & + .drawerDetailsItem {
    margin-top: 12px;
  }
}

.drawerDetailsItemKey {
  width: 100%;
  max-width: 140px;
}

.drawerDetailsTableDivider {
  margin: 0;
}

.drawerDetailsEmpty_ExtraSpacing {
  padding-bottom: 22px;
}
