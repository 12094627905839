.switcherTab {
  padding: 0 25px;
}

.spaceLeft {
  margin-left: 20px;
}

.actionContainer {
  justify-content: flex-end;
  align-items: center;
}
