.settingsButton {
  margin-right: 0px !important;
  max-height: 40px;
  max-width: 40px;
  width: 40px;
  height: 40px;
  font-size: 16px !important;

  svg {
    margin: -6px -12px;
  }

  @media screen and (max-height: 690px) {
    padding: 12px 18px !important;
  }
}

.settingsButtonFloating {
  position: absolute;
  z-index: 1;
  right: 6px;
  top: -16px;
}

.chartSettings.chartSettings {
  border-top: 0px solid #e3e9ee;
  margin: -10px -30px -30px -30px !important;
  height: auto;
  flex-wrap: nowrap;
}

.chartSettingsTabs {
  width: 70px;
  border-left: 1px solid #e3e9ee;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 60px 10px 60px 10px;
  flex-shrink: 0;
}

.chartSettingsTab {
  margin-bottom: 10px;
  color: #7c91a2 !important;
}

.chartSettingsTabActive {
  color: #52cccc !important;
  margin-bottom: 10px;
  font-weight: 600 !important;
}

.chartSettingsContent {
  max-width: calc(100% - 70px);
  padding: 15px;
  flex-grow: 1;
  flex-shrink: 0;
  height: 100%;
  overflow: auto;
  font-size: 12px;

  > div > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  > div > h5 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    font-weight: normal;
    margin: 20px 0 !important;

    &:first-child {
      margin-top: 0px !important;
    }
  }

  small {
    display: block;
    font-size: 11px;
    color: #7c91a2;
    padding-right: 20px;
    line-height: 1.1;
    display: block;
    overflow: hidden;
    clear: both;
    padding-top: 20px;
  }
}

.paletteSettings {
  height: 50px;
  margin-bottom: -8px;

  > * {
    margin-right: 8px;
    margin-bottom: 8px;
    float: left;
  }
}
