.popoverHeader {
  line-height: 1.5;
  font-weight: 600;
  color: #82929f;
  font-size: 10px;

  &:first-child:last-child {
    margin-bottom: -10px;
  }
}

.popoverHeader ~ * {
  margin-top: 10px;
}

.popoverHeaderLabel {
  font-size: 10px;
  font-weight: 400;
}

.popoverHeaderWithLabel {
  font-size: 12px;
}
