.toggleSettingsButton {
  width: 42px !important;
  height: 42px !important;
  background-color: transparent !important;
  border-radius: 6px;
  border: solid 1px var(--grey) !important;
  overflow: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--mid-grey);
}

.listItemToggle {
}

.listItem {
  font-size: 13px;
  font-weight: bold;
}

.menuNoOverflowX {
  overflow-x: hidden;
}
