.progress {
  padding: 0;
  align-items: unset;
  margin-left: 8px;
}

.aiInsightsContainer {
  display: flex;
}

.insights {
  line-height: 24px;
}

.insightsHeader {
  margin: 4px;
  padding-left: 4px;
  max-width: 'max-content';
}

.aiEyeIcon {
  margin-left: 2px;
}

.aiEyeIconContainer {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
}

.insightsButtonContainer {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.fetchInsightsButton {
  width: max-content;
  margin-left: 8px;
}

.insightsContainer {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-right: 12px;
}

.insightsActionButtonsContainer {
  display: flex;
  margin-top: 10px;
  padding: 0;
  padding-right: 12px;
  justify-content: right;
}

.insightsTipContainer {
  display: flex;
  max-width: max-content;
}

.insightsTip {
  display: flex;
  width: 320px;
  z-index: 9999;
}
