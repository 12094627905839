.imui-button {
  cursor: pointer;
  display: flex;
  outline: 0;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  background-color: #52cccc;
  border: solid 2px #52cccc;

  font-family: averta, Arial, sans-serif;
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
  transition: all 0.3s;

  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
    background-color: #93aabd !important;
    border: solid 2px #93aabd !important;
    opacity: 0.8;
  }

  &:hover {
    background-color: #36b5b5;
    border: solid 2px #36b5b5;
  }

  &.imui-button-s {
    padding: 2px 8px;
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;

    .imui-button-badge {
      margin: -4px -10px -4px 4px;
    }
  }

  &.imui-button-m,
  &.imui-button-l {
    padding: 10px 16px;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.5px;
    font-weight: normal;

    .imui-button-badge {
      margin: -4px -10px -4px 10px;
    }
  }

  &.imui-button-l {
    text-transform: uppercase;
    @media (--tablet) {
      padding: 16px 20px;
      font-size: 14px;
      line-height: 0.86;
      letter-spacing: 0.6px;
      font-weight: bold;

      .imui-button-badge {
        font-size: 14px;
        font-weight: bold;
        margin: -9px -10px -8px 10px;
        padding: 3px;
        border-radius: 15px;
      }
    }
  }

  &.imui-button-icon-only {
    align-items: center;
    justify-content: center;
    display: inline-flex;

    &:not(:disabled) > span {
      cursor: pointer !important;
    }

    &:last-child {
      &.imui-button-s {
        margin-right: -8px;
      }

      &.imui-button-m {
        margin-right: -18px;
      }

      &.imui-button-l {
        margin-right: -28px;
      }
    }
  }

  &.imui-button-secondary {
    background-color: transparent;
    border: solid 2px #52cccc;
    color: #52cccc;

    &:hover {
      color: #36b5b5;
      border: solid 2px #36b5b5;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: transparent !important;
      border: solid 2px #93aabd !important;
      color: #93aabd !important;
      opacity: 0.8;
    }
  }

  &.imui-button-dark {
    background-color: #272e42;
    border: solid 2px #272e42;
    color: #fff;

    &:hover {
      color: #fff;
      border: solid 2px #151923;
      background-color: #151923;
    }
  }

  &.imui-button-negative {
    background-color: #e3e9ee;
    border: solid 2px #e3e9ee;
    color: #2e2f40;

    &:hover {
      color: #2e2f40;
      border: solid 2px #d3dce4;
      background-color: #d3dce4;
    }
  }

  &.imui-button-alert {
    background-color: #fa3e3e;
    border-color: #fa3e3e;
    color: #fff;

    &:hover {
      background-color: #db1414;
      border-color: #f92424;
    }

    &.imui-button-secondary {
      background-color: transparent;
      color: #fa3e3e;

      &:hover {
        background-color: #f9242432;
        color: #f92424;
      }
    }
  }

  &.imui-button-clean {
    background-color: transparent;
    border: solid 2px transparent;
    color: #262a2d;

    &:hover {
      color: #151923;
      border: solid 2px transparent;
      background-color: transparent;
    }

    &.imui-button-secondary {
      color: #52cccc;
      &:hover {
        color: #36b5b5;
      }
    }
  }

  &.imui-button-text {
    padding: 0;
    background-color: transparent;
    border: 0px;
    color: #52cccc;

    &.imui-button-dark {
      color: #272e42;

      &:hover {
        color: #272e42;
      }
    }

    &.imui-button-alert {
      color: var(--red);

      &:hover {
        color: #f92424;
      }
    }

    &:hover {
      color: #52cccc;
      text-decoration: underline;
      border: 0px;
      background-color: transparent;
    }

    &:disabled {
      color: #cad1d1 !important;
      opacity: 0.7;
      background-color: transparent !important;
      border: none !important;
      cursor: disabled;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  &.imui-button-underlined {
    text-decoration: underline;
  }

  &.imui-button-border {
    border-radius: 6px;
    border: 1px solid var(--light-grey);

    &.imui-button-dark {
      border-color: var(--grey);
    }
  }

  &.imui-button-action {
    text-transform: uppercase;
  }

  &.imui-button-action,
  &.imui-button-actionSecondary {
    background: transparent;
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
    color: var(--mid-grey);
    border: 0;
    padding: 0;

    &:hover {
      color: var(--dark-blue);
    }
  }

  [class*='imui-icon'] {
    font-size: 1.2em;
    margin-top: -0.15em;
    margin-bottom: -0.15em;
    width: 1.2em;
    height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin-left: -4px !important;
    }

    &:last-child {
      margin-right: -4px !important;
    }
  }
}

span + .imui-button-label {
  margin-left: 4px;
}
