@import './TextField.module.css';

.imui-select-field-input {
  height: 42px !important;
  border: none;
  -webkit-appearance: none;
  outline: 0;
  font-family: averta, Arial, sans-serif !important;
  font-weight: 400;
  font-size: 14px !important;
  color: #221e20;

  & > div > div:first-of-type > div:nth-of-type(2) {
    height: 42px !important;
    line-height: 42px !important;
    top: 0 !important;
  }

  > div:nth-of-type(2) {
    background-color: #fff !important;
    border-radius: 6px !important;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07) !important;
  }

  button {
    padding: 0 12px !important;
    height: 42px !important;
    fill: #262a2d !important;
    position: relative;

    > div {
      height: 100%;
      position: relative;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.imui-select-field-input-outline {
  > div:nth-of-type(2) {
    box-shadow: none !important;
    border: solid 1px var(--grey) !important;
  }
}

.imui-select-field-input-transparent {
  > div:nth-of-type(2) {
    background-color: transparent !important;
  }
}

.imui-select-field-input-stick-right > div:nth-of-type(2) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.imui-text-field-input-stick-left > div:nth-of-type(2) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.imui-select-field-input-icon {
  width: 8px;
  height: 8px;
  fill: #262a2d;
  position: relative;
  top: 1px;
}

.imui-select-field-input-value {
  display: block;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0;
  margin-right: -10px;

  > div:first-child {
    margin-left: -10px;
    margin-right: 5px;
    display: inline-block;
    max-height: 22px;
  }
}

.imui-select-field-flatDark {
  > div:nth-of-type(2) {
    background-color: var(--greyish) !important;
    border-radius: 6px !important;
    box-shadow: none !important;
  }
}

.imui-select-field-icon {
  font-size: 16px;
  line-height: 44px !important;
  color: var(--dark) !important;
}

.imui-select-field-flat {
  > div:nth-of-type(2) {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.imui-select-field-border {
  > div:nth-of-type(2) {
    border: 1px solid #e8eaed !important;
  }
}
