.printWidgetIcon {
  border-radius: 50%;
  background-color: #52cccc !important;

  &:hover {
    background-color: #39b3b3 !important;
  }
}

@media print {
  .printWidget {
    display: none;
  }
}
