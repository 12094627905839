.search-field-wrapper {
  position: relative;
}

.search-field-icon {
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #b4c1cc;
}
