.option {
  &.option-readonly {
    @media print {
      display: initial;
    }
  }
  &.option-readonly.option-empty {
    @media print {
      display: none;
    }
  }
}

.label {
  display: flex;
  min-height: 24px;
  align-items: center;

  span {
    margin-right: 5px;
    flex: 1 1 60%;
  }
}
