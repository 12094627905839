.questionHeader {
  margin: 0px;
  padding-right: 40px;
}

.chartContainer {
  padding-bottom: 43.75% !important; /* prevent page jump on chart update */
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.chartPlusTabsWrapper {
  display: flex;
  flex-direction: column;
}

.chartPlusTabsWrapper .tabsWrapper {
  align-self: flex-end;
}

.contentContainer {
  + * {
    margin-top: 12px;
  }
}

.content:not(:first-child) {
  margin-top: 20px;
}

.chartHidden {
  display: none;
}

.emptyNoChart {
  margin-bottom: 20px;
}

.toggleDataTableButton {
  background-color: #f4f6f9 !important;
  position: absolute !important;
  left: -20px;
  bottom: -20px;
  border-radius: 0 10px 0 0;
}

.actionIcon {
  font-size: 16px;
  cursor: pointer !important;
}

.info {
  font-size: 14px;
  color: var(--mid-grey);
}

.tabActions {
  height: 42px;
  max-height: 42px;
}
.tabActions > div {
  padding: 8px;
  overflow: hidden;
  border: 0;
}
.tabActions > div svg {
  width: 64px;
  max-width: 48px;
}
