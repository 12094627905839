.header {
  background-color: var(--seafoam);
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 48px;

  .title {
    color: inherit;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 0.86;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }

  > :first-child {
    align-content: flex-start;
    > * {
      margin-right: 8px;
    }
  }

  > :last-child {
    align-content: flex-end;
    > * {
      margin-left: 8px;
    }
  }
}

.editQuestionContent {
  padding: 16px 20px 20px;
  border-radius: 0px 0px 6px 6px;
  border: 2px solid var(--seafoam);
  border-top: 0px;
  background: var(--white);

  h3 {
    margin-top: 8px;
  }
}

.editlayoutElementContent {
  border-style: dashed;
}

.footer {
  margin-top: 16px;
}

.subSection {
  border-top: 1px solid #f0f2f5;
  margin-top: 16px;
  padding-top: 16px;
}

.expandChildrenFixed {
  > * {
    width: 250px;
  }
}
