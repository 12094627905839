.titleWrapper {
  flex: 1 1 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 24px;
  line-height: 1.17;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.leftActions,
.rightActions {
  flex: 0 2 100%;
  display: flex;
  align-items: center;
}

.leftActions {
  justify-content: flex-start;
  > {
    margin-right: 8px;
    &:last-child {
      margin-right: 0px;
    }
  }
}

.rightActions {
  justify-content: flex-end;
  > {
    margin-left: 8px;
    &:first-child {
      margin-left: 0px;
    }
  }
}
