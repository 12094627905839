.noSpaceTop {
  margin-top: 0;
}

.titleDesc {
  line-height: 22px;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: var(--dark);
  margin: 0 0 24px;
}

.tagCategoriesList {
  max-width: 100%;
  min-height: 530px;
  flex: 1;
  padding: 0;
  margin-bottom: 32px;
}

.tagCategoryPlaceholder {
  list-style: none;
  margin-bottom: 8px;
  padding-left: 30px;
}

.tagCategoriesListToggle {
  display: inline-block;
  flex: 0 0 auto;
  margin: 10px auto 0px 6px;
  font-size: 12px;
}

.tagCategories {
  padding-top: 8px;
}

.analysisTagsContainer {
  margin: 0 auto;
  max-width: 800px;
}
