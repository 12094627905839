.imagePreview {
  margin: 16px;
  background-position: 0% 50%;
  background-size: contain;
  max-width: 50%;
  height: 150px;
  box-sizing: border-box;
  display: block;
  background-repeat: no-repeat;
}

.secondaryAction {
  font-size: 12px;
  margin-top: 8px;
  display: block;
}

.duplicatePopover {
  min-width: 352px !important;
  max-width: 352px !important;
}
