.wrapper {
}

.noData {
  text-align: center;
  font-style: italic;
}

.header {
}

.tab {
  font-size: 13px !important;
  text-transform: none !important;
}

.tabContent {
  padding-top: 10px;
  padding-bottom: 60px;
}

.fundingTableBody > thead > th:last-child {
  text-align: right;
}
.fundingTableBody > tr > td:last-child {
  text-align: right;
}
.fundingTableBody > tr > td:nth-last-child(2):not(:first-child) {
  text-align: right;
}
.fundingTableBody > tr > td:first-child {
  text-align: left;
}
.fundingTableBody > tr > th:last-child {
  text-align: right;
  font-weight: 600;
}
.fundingTableBody > tr > th:nth-last-child(2):not(:first-child) {
  text-align: right;
  font-weight: 600;
}
.fundingTableBody > tr > th:first-child {
  text-align: left;
  font-weight: 600;
}
.fundingTableBody > tr > th {
  font-weight: 600;
}

.fundingTableHeader {
  border-top: 10px solid transparent;
  &:last-child {
    margin-top: 0;
  }
}

.tabContentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 300px;

  > div {
    overflow: auto;
    position: absolute !important;
  }
}
