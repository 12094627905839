.tagCategoryForm {
  padding: 8px 0;
}

.dividerSpacing {
  margin-top: 5px;
}

.formErrorList {
  list-style: none;
  margin: 0;
  padding: 0;
}
