.imui-text-field-error {
  width: 100%;
  height: 16px;
  font-size: 12px;
  color: #f82020;
  padding-top: 3px;
  opacity: 0;
  font-family: averta, Arial, sans-serif;

  white-space: nowrap;
  text-overflow: ellipsis;

  transition: opacity 0.2s ease-in-out;
}

.imui-text-field-error-visible {
  opacity: 1;
}
