.tagCategoryItemText {
  margin-right: 8px;

  span[role='img'].tagCategorySelectorItemIcon {
    margin-right: 8px !important;
  }
}

.tagCategorySelectorItemIcon {
  display: inline-block;
  font-size: 16px;
  cursor: pointer !important;
  margin-right: 8px;
  color: var(--dark-grey);
}
