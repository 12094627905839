.grandContainer > div > div {
  /* Monkey patch solution to put absolute to
   * the relevant container div */
  position: absolute;
}

.taggingSuggestionEditorContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.taggingSuggestionEditorActions {
  display: flex;
  gap: 12px;
}

.reportPreviewContainer {
  position: relative;
  height: 50vh;
  overflow: hidden;
  border-radius: var(--default-radius);
  border: 1px solid var(--black);
  background: #ffffff60;
}

.reportPreviewContainer > label {
  padding-bottom: 8px;
}

.suggestedTaggingHeader {
  display: flex;
  gap: 8px;
}

.suggestedTaggingContainer {
  padding: 20px;
  border-radius: var(--default-radius);
  border: 1px solid var(--grey);
  background: var(--white);
  font-size: 16px;
  line-height: 24px;
  font-family: averta, Arial, sans-serif !important;
}

.suggestedTaggingTypeDropdown {
  min-width: 200px;
}

.suggestedTaggingSelectedText {
  font-size: 14px;
  line-height: 24px;
  font-family: averta, Arial, sans-serif !important;
  padding: 20px;
  color: var(--dark);
}
