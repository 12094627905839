.wrapper {
  display: inline;
}

.children {
  text-decoration: none;
  cursor: pointer;
  color: #262a2d;

  &:hover {
    text-decoration: underline;
  }
}

.grantees {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.grantee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.granteeTag {
  margin-right: 8px !important;
}

.granteeAction {
  padding: 4px !important;
  width: 20px !important;
  height: 20px !important;

  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.granteeLabel {
  text-decoration: none;
  color: #077678;
  font-size: 12px;

  &:hover {
    color: #044748;
    text-decoration: underline;
  }
}
