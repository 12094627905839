.dropzone {
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
  min-width: 44px;
  min-height: 44px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px dashed #52cccc;
  border-radius: 6px;
  background-color: #f0f2f5;
  color: #7c91a2;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-align: center;
  transition:
    color 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }
  .dropzone-file-list-item {
    text-align: center;
  }
  .dropzone-icon-delete {
    font-size: 32px;
  }
}
.dropzone-active {
  border-color: #8097ba;
  background-color: #c9d3e2;
  color: #a8b8d0;
}

.dropzone-rejected {
  border-color: #ea695e;
}

.dropzone-icon-delete {
  position: absolute;
  top: 1px;
  right: 2px;
  font-size: 16px;
  color: #ea695e;
  font-weight: 800;
}
.dropzone-icon-delete:hover {
  color: #ca1303;
}
.dropzone-preview {
  width: 100%;
  flex: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}
.dropzone-file-list {
  list-style: none;
  padding: 0px 4px;
  font-size: 14px;
  margin: 0;
}
.dropzone-file-list-item {
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
