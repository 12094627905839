.taggingDetailsSelector {
  position: absolute;
  z-index: 10;
}

.detailsSelector {
  min-width: 320px;
  background: var(--white);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
}

.detailsSelectorInput {
  margin-top: 12px;
}

.detailsSelectorHeader {
  padding: 12px 16px 0;
}

.detailsSelectorTitle {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0;
  line-height: 1;
}

.detailsSelectorTitleContent {
  display: flex;
  justify-content: space-between;
}

.detailsSelectorTitleActions {
  display: flex;
  align-items: center;
}

.detailsSelectorDivider {
  margin: 12px 0 0;
  position: relative;

  &:after {
    content: '';
    height: 1px;
    position: absolute;
    left: -16px;
    right: -16px;
    top: 0;
    background: var(--light-grey);
  }
}

.detailsSelectorContent {
  padding: 16px;
}

.detailsSelectorDropdownItem {
  margin: 4px 0 !important;

  span[role='img'] {
    width: auto !important;
    height: auto !important;
    top: 0 !important;
  }
}
