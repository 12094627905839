.tableBottomScrollWrapper {
  max-width: 100%;
  overflow-x: auto;

  padding-bottom: 17px;
  margin-bottom: -17px;
}

.tableBottomScroll {
  height: 17px;
  width: 100%;
  max-width: 100%;

  overflow-x: auto;

  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 3;

  background-color: var(--white);

  > div {
    height: 100%;
    /* Needed in windows to have scrollbar visible */
    display: inline-block;
  }
}
