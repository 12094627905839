.imui-checkbox {
  &.imui-checkbox-readonly {
    cursor: not-allowed !important;

    input {
      pointer-events: none !important;
    }
  }

  &.imui-checkbox-no-label {
    display: inline-block !important;
    width: auto !important;

    input + div > div {
      margin-right: 0 !important;
    }

    svg {
      left: 0;
    }
  }

  input + div > div {
    width: 19px !important;
    height: 19px !important;
    top: 2px;
  }

  label {
    font-family: averta, Arial, sans-serif !important;
    font-size: 14px;
  }

  svg {
    width: 19px !important;
    height: 19px !important;
  }
}
