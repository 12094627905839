.popoverActions {
  padding: 20px;
  list-style: none;
  margin: 0;
}

.popoverActionsItem + .popoverActionsItem {
  margin-top: 24px;
}

.popoverActionsItemButton {
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  color: #221e20;
  background: none;

  > span {
    cursor: inherit !important;
  }
}

.popoverActionsItemText {
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: -2px;
}
