.input {
  flex: 1 1 0;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #e8eaed;
  padding: 0 10px;
  outline: 0;
  font-size: 14px;
  font-weight: 300;
  color: #333;
}
[type='radio'] {
  cursor: pointer;
}

.row {
  display: flex;
  flex-flow: column wrap;
  padding: 10px 0;
  border-bottom: 1px solid #f0f2f5;

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}

[class*='description'] + div .row:first-child {
  padding-top: 0;
}

@media print {
  .row,
  .title {
    display: block;
  }
}

.row:not(.rowMobileStyling) {
  @media (--tablet) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .title {
    @media (--tablet) {
      padding: 0px 10px 0;
    }
  }
}

.title {
  flex: 0 0 50%;
  padding: 0px 10px 11px 0;
  display: flex;
  flex-direction: column;
  align-self: center;

  > div {
    margin-top: 5px;
    align-self: flex-start;
  }
}

.title + div {
  align-self: flex-start;
}

.hasOptions .title + div {
  align-self: unset;
}

.field {
  display: flex;
  justify-content: flex-end;
  line-height: 36px;
  flex: 0 0 50%;
}

.field + div {
  margin-top: 5px;
}

.spaceLeft {
  margin-left: 10px;
}

.printVisible {
  display: none;

  @media print {
    display: block;
  }
}

.hasOptions {
  &.header {
    border-bottom: 0;
    border-left: 0;
    background-color: #f0f2f599;
    border-radius: 6px;
    > :first-child {
      border-bottom: 0;
      border-left: 0;
    }
    > :first-child > div {
      border-bottom: 0;
      border-left: 0;
    }
  }

  > :first-child {
    display: flex;
    width: 100%;
    flex-grow: 1;
    border-bottom: 1px solid #f0f2f5;

    label {
      width: 30%;
      flex-basis: 30%;
      flex-shrink: 0;
      flex-grow: 0;
    }

    > div {
      flex: 1;
      text-align: center;
      border-left: 1px solid #f0f2f5;
      border-bottom: 1px solid #f0f2f5;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  select {
    display: none;
  }
}

.hasManyOptions {
  > :first-child {
    > div {
      font-size: 12px;
    }
  }
}

.hasTooManyOptions {
  &.header {
    display: none;
  }

  > :first-child {
    > div {
      display: none;
    }
  }

  select {
    display: block;
  }
}

@media (--mobile) {
  .hasOptions {
    &.header {
      display: none;
    }

    > :first-child {
      label {
        width: 50%;
        flex-basis: 50%;
        padding-bottom: 0;
      }

      > div {
        display: none;
      }
    }

    select {
      display: block;
      text-overflow: ellipsis;
      align-self: flex-start;
      padding: 10px 6px;
      width: 100%;
      border-color: #e3e9ee;
      background: transparent;
      border-radius: 6px;
    }
  }
}

.rowMobileStyling {
  &.header {
    display: none !important;
  }

  > :first-child {
    label {
      width: 50%;
      flex-basis: 50%;
      padding-bottom: 0;
    }

    > div {
      display: none;
    }
  }

  select {
    display: block;
    text-overflow: ellipsis;
    align-self: flex-start;
    padding: 10px 6px;
    width: 100%;
    border-color: #e3e9ee;
    background: transparent;
    border-radius: 6px;
  }
}

.selectEmpty {
  color: #94a4b2;
}

.printTextField {
  padding: 6px 20px;
  color: rgb(34, 30, 32);
  border: 1px solid #e8eaed;
  margin-bottom: -4px;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 14px;
  line-height: 24px;
  white-space: pre-wrap;
  height: 100%;
  width: 100%;
  min-height: 42px;
}

.printTextFieldEmpty {
  background-color: var(--greyish);
}
