.limitedWidth {
  max-width: 1024px;
  margin: 0 auto;
}

.textSoft {
  color: var(--soft-purple);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.subtitleSoft {
  color: var(--soft-purple);
  font-size: 16px;
  line-height: 24px;
}
.subtitleSoft h3 {
  font-size: 28px;
  margin-top: 0;
}

.textWithIcon {
  align-items: center;
  margin-top: 8px;

  span {
    margin-right: 8px;
  }
}

.subtitleError {
  color: var(--red);
  line-height: 2;
  margin-top: 32px;
}
.textError {
  display: inline-block;
  color: var(--red);
  line-height: 1.5;
  margin-top: 8px;
}

.listError {
  list-style: none;
  padding: 0;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}
.listItemError {
  list-style: none;
  font-size: 12px;
}
.listError li {
  padding: 4px 12px;
}

.listLimitedHeight {
  overflow-y: auto;
  max-height: 600px;
}
