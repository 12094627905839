.contactItemLight {
  font-weight: normal;
  word-break: break-all;
}

.contactItemIcon {
  cursor: pointer;
}

.contactItem {
  margin-bottom: 5px;

  &:last-of-type {
    padding-bottom: 15px;
  }
}

.spaceTop {
  margin-top: 20px;
}

.spaceBottom {
  margin-bottom: 20px;
}

.placeholder {
  font-style: italic;
}
