.drawerDetailsHeader {
  margin-bottom: 32px;
  flex: 0 0 auto;
  flex-wrap: nowrap;
}

.drawerDetailsActions {
  align-self: flex-start;
  flex-wrap: nowrap;
  color: var(--mid-grey);

  > span {
    margin-left: 16px;
  }
}
.drawerDetailsActions .icon {
  font-size: 22px;
  margin-left: 24px;
}
