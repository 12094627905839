.centeredText {
  width: 100%;
  display: block;
  text-align: center;
}

.exportText {
  line-height: 1.5 !important;
  letter-spacing: 0.5px;
  color: var(--soft-purple) !important;
  margin: 24px;
  text-align: center;
  white-space: pre-line;
}
