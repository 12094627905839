.title {
  margin-bottom: 10px;
}

.subtitle {
  text-align: center;
}

.uploadReference {
  color: gray;
}
