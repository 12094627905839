.tableWrapper {
  margin: 0;
  /* max-height: calc(100vh - 120px); */
  overflow: auto;

  thead td:first-child {
    width: 50px;
  }
}

.fixedWidthCell {
  width: 200px;
}

.tagContainer > * {
  margin: 0;
}
.tagsListAnswer {
  width: 60%;
}
.tagsListTag {
  width: 40%;
}
.tagCellAnswer {
  > * {
    margin-bottom: 4px;
    margin-right: 4px;
    &:last-child {
      margin-bottom: 0;
      margin-right: 0px;
    }
  }
}
.tagCellTag {
  > * {
    margin-bottom: 4px;
    margin-right: 4px;
    &:last-child {
      margin-bottom: 0;
      margin-right: 0px;
    }
  }
}

.editorTagsSelector {
  flex-wrap: nowrap;
}
.popoverTagsSelector {
  max-width: 80vw;
  right: unset !important;
  left: 32px !important;
  max-height: 80vh;
  top: 10vh !important;
  bottom: 10vh !important;
}
.popoverTagsSelector div[class^='imui-popover-content'] {
  max-height: calc(80vh - 120px);
  flex-grow: 1;
}
