.wrapper {
  width: 100%;
}

.submitButton {
  min-width: 190px;
}

.surveyItemsList {
  padding-bottom: 64px;

  > * {
    margin-bottom: 24px;
  }
}

.mergeTagFormDivider {
  margin-top: 24px;
  margin-bottom: 10px;
  height: 1px;
  border-bottom: 1px solid lightgrey;
}

.divider {
  padding-bottom: 16px;
  margin-top: 12px;
  border-top: 1px solid #eaeaea;
}

.editorTags {
  display: flex;
  flex-direction: row;
}

.quotesTags {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0;
  border: 0;
  width: 100%;
}
.quotesTagsDisabled {
  .quote {
    touch-action: none !important;
    pointer-events: none;
    cursor: disabled !important;
    opacity: 0.7 !important;
  }
}
.editQuestionAttachmentsDisabled {
  touch-action: none !important;
  pointer-events: none;
  opacity: 0.6 !important;
}

.editorTagsSelector {
  flex-wrap: nowrap;
}
.popoverTagsSelector {
  max-width: 80vw;
  left: unset !important;
  right: 32px !important;
  max-height: 80vh;
  top: 10vh !important;
  bottom: 10vh !important;
}
.popoverTagsSelector div[class^='imui-popover-content'] {
  max-height: calc(80vh - 110px);
  flex-grow: 1;
}

.surveyLegalInfo {
  margin-bottom: 24px;
  text-align: center;
  page-break-inside: avoid;
}

@media print {
  .footer,
  .editorTags {
    display: none;
  }

  .printNonBreakable {
    page-break-inside: avoid;
  }

  .surveyLegalInfo {
    font-size: 7pt;
    a {
      text-decoration: none !important;
    }
    a:link:after,
    a:visited:after {
      content: ' [' attr(href) '] ';
    }
  }
}
