.propertyLabelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: var(--soft-purple);
}

.description {
  width: 100% !important;
  font-size: 13px !important;
}

.settings {
  margin-top: 16px !important;
  padding-top: 16px;
  border-top: 1px solid var(--seafoam);
}
