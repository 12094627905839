.imui-card-footer {
  padding: 16px 20px;
  margin: 0px -20px -20px;
  padding-bottom: 20px;
  background-color: #f4f6f9;
  overflow-x: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.imui-card-footer-minimal {
  padding: 0;
  margin: 8px 0 4px 0;
  padding-top: 4px;
  background-color: transparent;
  overflow-x: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.imui-card-footer-no-scroll {
  overflow: visible;
}
.imui-card-footer-no-padding {
  /* padding: 4px 4px; */
}

.imui-card-footer-light {
  background-color: var(--white);
  border-top: 1px solid var(--light-grey);
}
