.trintDocumentDropdown > div {
  left: 0 !important;
}

.trintDocumentDropdown > input {
  padding-left: 0 !important;
}

.autocompleteColumn {
  width: 200px;
  overflow: hidden;
}
