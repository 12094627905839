.wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 10px;
}

.wrapperDark {
  background: #333;

  .entityName {
    color: #eee;
  }

  .secondaryValue {
    color: #eee;
  }
}

.countryName {
  text-transform: uppercase;
  line-height: 1.3em;
  font-size: 20px;
  font-weight: 400;
  margin: 8px 0;
}

.yearSpan {
  font-weight: 400;
  font-size: 18px;
  margin: 8px 0;
}

.entityName {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

.relatedEntityName {
  font-size: 11px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.secondaryInfoWrapper {
  margin-top: 2px;
  margin-bottom: 2px;
}

.secondaryInfo {
  font-size: 12px;
  margin-right: 4px;
}

.tagGroup {
  font-size: 12px;
  margin-bottom: 4px;
  margin-top: 8px;
  font-weight: 400;
}

.secondaryValue {
  font-size: 12px;
}

.entitiesListItem {
  margin-top: 16px;
  margin-left: 6px;
  padding-left: 6px;

  & > h3 {
    margin: 0;
  }
}

.entitiesList {
  height: 100%;
  padding-right: 24px;
  margin-top: -16px;
}

.entitiesListWrapper {
  align-self: stretch;
  margin-right: -20px;
  overflow-y: auto;
}
