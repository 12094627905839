.stats {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0 0 0 60px;
  justify-content: space-between;
}

.stat {
  text-align: center;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.value {
  display: block;
  margin: 0 0 3px;
  font-size: 32px;
  line-height: 1;
  color: #82929f;
}

.label {
  font-size: 12px;
  font-weight: 300;
  color: #82929f;
}
