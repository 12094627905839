.grantAddTiles {
  max-height: 500px;
  min-height: 500px;
}

.grantAddTileContainer {
  justify-content: center;
}

.grantAddTileSwitcher {
  position: absolute;
  top: 35px;
  right: 35px;
  color: var(--mid-grey);
  transform: scale(-1, 1);
}

.grantAddTileSwitcherHistory {
  top: 20px;
  right: 20px;
  color: var(--white);
  transform: scale(1, -1);
}

.grantAddTileTitleHistory {
  font-weight: bold;
}

.grantAddTileHistoryButton {
  margin-right: auto;
}

.grantAddTileList {
  max-height: 280px;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 30px 0 20px;
}

.grantAddTileListItem {
  padding: 0 15px 15px 0;
  margin-bottom: 15px;
  border-bottom: 1px dashed var(--light-grey);
  cursor: pointer;

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.grantAddTileListItemName {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--soft-purple);
}

.grantAddTileListItemInfo {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: var(--soft-purple);
  margin-top: 4px;
  align-items: center;

  span:first-of-type {
    margin-right: 8px;
  }

  span:last-of-type:not(:first-of-type) {
    margin-left: 8px;
  }
}

.grantAddSpacing {
  padding: 30px 0;
}
