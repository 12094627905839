.imui-button-sticky {
  padding: 11.5px 18px !important;

  &.imui-button-sticky-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &.imui-button-sticky-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
