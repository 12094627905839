.textSoft {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.textWithIcon {
  align-items: center;
  margin-top: 4px;

  span {
    margin-right: 4px;
  }
}

.listSuccessful {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listSuccessfulItem {
  position: relative;
  font-size: 12px;
  line-height: 2;
  margin-top: 8px;
}

.listSuccessfulIndicator {
  width: 9px;
  height: 9px;
  display: inline-block;
  border-left: 1px solid var(--mid-grey);
  border-bottom: 1px solid var(--mid-grey);
  margin: 0 11px 0 5px;

  position: relative;
  bottom: 3px;
}
