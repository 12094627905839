.filterSelected {
  justify-content: flex-start;
  > * {
    margin-bottom: 20px;
  }
}

.filterSelectedIcon {
  display: inline-block;
  align-self: center;
  font-size: 16px;
  color: var(--mid-grey);
  margin-right: 16px;
}

.filterSelectedIconAdd {
  color: var(--seafoam);
  align-self: center;
  transition: color 0.3s;

  &:hover {
    color: var(--ocean);
  }
}
