.headerFixed {
  position: fixed;
  left: 110px;
  right: 0;
  background: #f4f6f9;
}
.headerFirstRow {
  min-height: 79px;
}
.headerWithSteps {
  top: -79px;
  min-height: 128px;
  flex-direction: column;
  align-items: stretch;
  position: sticky;
  z-index: 20;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.7)
  );
}

.hasFooter {
  bottom: 64px !important;
}

.titleWrapper {
  position: relative;
  flex-grow: 1;
}

.pageTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0px;
  right: 20px;

  &:not(:first-child) {
    left: 50px;
  }
}
