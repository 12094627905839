@import './index.module.css';

.titleThick {
  font-weight: bold;
  margin: 0;
}

.titleWarning {
  color: var(--red);
}

.reviewSection + .reviewSection {
  padding-top: 20px;
}

.reviewSectionHeader {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 20px;
}

.reviewSectionListItem:last-of-type {
  padding-bottom: 20px;
}

.reviewSectionWarningText {
  color: var(--red);
}

.reviewSectionWarningTextIcon {
  margin-right: 8px;
}

.reviewWarningsContainer {
  margin-bottom: 20px;
  text-align: right;
  color: var(--soft-purple);
}

.reviewWarningTitle {
  margin-bottom: 10px;
  font-weight: bold;
}

.reviewWarningError {
  margin: 0 0 10px;
}
