.property-box + .property-box {
  margin-top: 16px;
}

.property-box > * {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}
