.tagSelector-popover-tagGroup,
.tagSelector-popover-tag,
.tagSelector-popover-selectable-tagGroup {
  white-space: normal !important;
  word-break: break-word !important;
  line-height: 1.33 !important;
  letter-spacing: 0.5px !important;
  font-size: 12px !important;
}

.tagSelector-popover-tagGroup {
  text-transform: uppercase;
  color: #7c91a2 !important;
}

.tagSelector-popover-tag {
  color: #262a2d !important;
}

.tagSelector-popover-tag label,
.tagSelector-popover-selectable-tagGroup label {
  line-height: 1.5 !important;
  padding: 3px 0 2px;
  font-size: 12px !important;
}

.tagSelector-popover-selectable-tagGroup label {
  text-transform: uppercase;
  color: #7c91a2 !important;
}

.tagSelector-popover-child-categories {
  padding-left: 16px;
}
