.tagCategoryItem {
  display: block;
}
.tagCategoryPopover {
  max-height: 36px;
  height: 36px;
}

.tagCategoryItem:hover .tagCategoryHoverIcon {
  opacity: 1;
  pointer-events: auto;
}

.tagCategoryHoverIcon {
  opacity: 0;
  color: var(--soft-purple);
  transition: opacity 0.3s;
  pointer-events: none;
}

.buttonText {
  margin-left: 8px;
  margin-top: 4px;
}
