.wrapper {
  width: 100%;
  padding-right: 24px;
  &:last-child {
    padding-right: 0;
  }

  @media (max-width: 700px) {
    width: 100% !important;
    padding-right: 0;
  }

  &.flexColumnNeighbourSpacing {
    padding-right: 0;
    margin-right: 10px;
    width: calc(50% - 10px) !important;

    & + .flexColumnNeighbourSpacing {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}
