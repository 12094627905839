.pageCustom {
  padding-bottom: 0;
}

.actionSameSize {
  flex: 1;
}

.actionCentered {
  justify-content: center !important;
}

.headerSticky,
.subHeaderSticky {
  position: sticky;
  left: 0;
}

.subHeaderSticky {
  background-color: var(--pale-grey);
  top: 0;
  z-index: 3;
}

.cardFooter {
  display: flex;
  margin-top: 10px;

  > div:first-child {
    flex: 1;
  }
}
