.radio {
  &.radio-readonly {
    @media print {
      display: initial;
    }
  }

  &.radio-readonly.radio-empty {
    @media print {
      display: none;
    }
  }
}

.label {
  display: flex;
  flex-direction: column;
  min-height: 24px;
  align-items: flex-start;
  justify-content: center;
}
