@charset "UTF-8";

@font-face {
  font-family: 'impactmapper';
  src: url('fonts/impactmapper.eot');
  src:
    url('fonts/impactmapper.eot?#iefix') format('embedded-opentype'),
    url('fonts/impactmapper.woff') format('woff'),
    url('fonts/impactmapper.ttf') format('truetype');
  /* url("fonts/impactmapper.svg#impactmapper") format("svg"); */
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'impactmapper' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'impactmapper' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account:before {
  content: '\61';
}
.icon-filter:before {
  content: '\63';
}
.icon-search:before {
  content: '\65';
}
.icon-settings:before {
  content: '\66';
}
.icon-trash:before {
  content: '\67';
}
.icon-comments:before {
  content: '\68';
}
.icon-drag:before {
  content: '\69';
}
.icon-switch:before {
  content: '\6a';
}
.icon-view:before {
  content: '\6b';
}
.icon-view-off:before {
  content: '\6d';
}
.icon-merge:before {
  content: '\6e';
}
.icon-chevron-down:before {
  content: '\64';
}
.icon-chevron-left:before {
  content: '\6c';
}
.icon-chevron-right:before {
  content: '\6f';
}
.icon-chevron-up:before {
  content: '\70';
}
.icon-close-large:before {
  content: '\71';
}
.icon-close:before {
  content: '\72';
}
.icon-collapse:before {
  content: '\73';
}
.icon-paperclip:before {
  content: '\75';
}
.icon-move:before {
  content: '\76';
}
.icon-download:before {
  content: '\77';
}
.icon-arrow-left:before {
  content: '\78';
}
.icon-arrow-right:before {
  content: '\79';
}
.icon-arrow-up:before {
  content: '\7a';
}
.icon-arrow-down:before {
  content: '\74';
}
.icon-dot-dot-dot:before {
  content: '\41';
}
.icon-skip:before {
  content: '\42';
}
.icon-upload:before {
  content: '\43';
}
.icon-warning-triangle:before {
  content: '\44';
}
.icon-tag:before {
  content: '\45';
}
.icon-table:before {
  content: '\46';
}
.icon-thumbnails:before {
  content: '\47';
}
.icon-rotate:before {
  content: '\48';
}
.icon-send:before {
  content: '\49';
}
.icon-grants:before {
  content: '\4b';
}
.icon-copy:before {
  content: '\4c';
}
.icon-calendar:before {
  content: '\4d';
}
.icon-hybrid:before {
  content: '\50';
}
.icon-check:before {
  content: '\51';
}
.icon-edit:before {
  content: '\62';
}
.icon-globe:before {
  content: '\52';
}
.icon-grantee:before {
  content: '\53';
}
.icon-list:before {
  content: '\54';
}
.icon-star-empty:before {
  content: '\55';
}
.icon-currency:before {
  content: '\56';
}
.icon-location:before {
  content: '\57';
}
.icon-number:before {
  content: '\58';
}
.icon-multiple:before {
  content: '\59';
}
.icon-question-1:before {
  content: '\5a';
}
.icon-share:before {
  content: '\30';
}
.icon-add-element:before {
  content: '\31';
}
.icon-burger:before {
  content: '\32';
}
.icon-filled-down:before {
  content: '\33';
}
.icon-filled-left:before {
  content: '\34';
}
.icon-filled-right:before {
  content: '\35';
}
.icon-filled-up:before {
  content: '\36';
}
.icon-text-element:before {
  content: '\38';
}
.icon-break:before {
  content: '\21';
}
.icon-files:before {
  content: '\22';
}
.icon-clipboard:before {
  content: '\23';
}
.icon-file-image:before {
  content: '\25';
}
.icon-email:before {
  content: '\26';
}
.icon-file-blank:before {
  content: '\28';
}
.icon-coordinate:before {
  content: '\29';
}
.icon-print:before {
  content: '\2a';
}
.icon-star-filled:before {
  content: '\2b';
}
.icon-wait:before {
  content: '\2c';
}
.icon-text-field:before {
  content: '\2d';
}
.icon-subhead-element:before {
  content: '\37';
}
.icon-headline-element:before {
  content: '\39';
}
.icon-conditional:before {
  content: '\2e';
}
.icon-translate:before {
  content: '\24';
}
.icon-intermediary:before {
  content: '\2f';
}
.icon-connector:before {
  content: '\3a';
}
.icon-processing:before {
  content: '\3b';
}
.icon-hybrid-trend:before {
  content: '\3d';
}
.icon-bars:before {
  content: '\3e';
}
.icon-line:before {
  content: '\3f';
}
.icon-pie:before {
  content: '\40';
}
.icon-timeline:before {
  content: '\5b';
}
.icon-compare:before {
  content: '\5d';
}
.icon-plus:before {
  content: '\5e';
}
.icon-asterix:before {
  content: '\5f';
}
.icon-folder:before {
  content: '\60';
}
.icon-warning-circle:before {
  content: '\3c';
}
.icon-explore:before {
  content: '\27';
}
.icon-file:before {
  content: '\7b';
}
.icon-order:before {
  content: '\7c';
}
.icon-data:before {
  content: '\7d';
}
.icon-calculator:before {
  content: '\7e';
}
.icon-question:before {
  content: '\5c';
}
.icon-tag-currency:before {
  content: '\e000';
}
.icon-tag-date:before {
  content: '\e001';
}
.icon-tag-default:before {
  content: '\e002';
}
.icon-tag-location:before {
  content: '\e003';
}
.icon-tag-name:before {
  content: '\e004';
}
.icon-tag-number:before {
  content: '\e005';
}
.icon-bubble:before {
  content: '\e006';
}
.icon-scatter:before {
  content: '\e008';
}
.icon-heatmap:before {
  content: '\e009';
}
.icon-stacked:before {
  content: '\e007';
}
.icon-area:before {
  content: '\4e';
}
.icon-folder-closed:before {
  content: '\4f';
}
.icon-folder-add:before {
  content: '\e00a';
}
.icon-blog:before {
  content: '\e00b';
}
.icon-stories:before {
  content: '\4a';
}
.icon-question-filled:before {
  content: '\e00c';
}
.icon-organization:before {
  content: '\e00d';
}
