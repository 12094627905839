.exportButton {
  margin: 0 -18px 0 0 !important;
}

.infoBar {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  flex-direction: row !important;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column !important;
  }
}

.filters {
  width: 40%;
  flex-shrink: 0;
  padding-right: 20px;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 25px;
    padding-right: 0;
    justify-content: center;
  }
}

.stats {
  flex: 0 0 500px;
  gap: 20px;
  flex-shrink: 0;
  justify-content: space-around;

  @media (max-width: 1024px) {
    width: 100%;
    flex: 1 1 100%;
  }
}
.twoColumns {
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    display: block;
  }
}
.reachTags {
  flex: 1 0 50%;
}

.demographicsDetails {
  display: flex;
  flex-direction: row !important;
  gap: 16px;
  > :first-child {
    flex: 1 1 50%;
    width: 50%;
    > div {
      height: 100%;
      overflow: hidden;
    }
  }
  > :last-child {
    flex: 1 0 50%;
    width: 50%;
  }

  @media (max-width: 1280px) {
    flex-direction: column-reverse !important;
    > :first-child,
    > :last-child {
      flex: 1 1 100%;
      width: 100%;
    }
    > :first-child {
      height: 400px;
    }
    > :last-child {
      margin-bottom: 20px;
    }
  }
}

.mapWrapper {
  @media (max-width: 1280px) {
    width: auto;
  }
}

.demographics {
  width: 40%;
  flex-shrink: 0;
  padding-right: 20px;

  @media (max-width: 1280px) {
    width: auto;
    padding-right: 0;
  }
}

.map {
  width: 60%;
  flex-shrink: 0;
  @media (max-width: 1280px) {
    width: auto;
    height: 400px;
    margin-bottom: 25px;
  }
}

.seeMap {
  text-decoration: underline;
  color: #333;
  text-align: center;
  display: block;
  font-size: 12px;
}

.header {
  margin: 16px 0;
}

.headerMinSpacing {
  margin: 0 0 16px;
}
