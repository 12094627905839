.imui-text-field {
  display: block !important;
  height: auto !important;
  width: 100% !important;
  font-family: averta, Arial, sans-serif !important;

  input[disabled] {
    opacity: 0.8 !important;
    background-color: #f4f4f4b8 !important;
    color: #272e429b !important;
  }

  &.imui-text-field-has-hint {
    /* hintText */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      color: transparent;
      z-index: 1;
    }
    > div:first-of-type {
      bottom: auto !important;
      top: 8px;
      left: 20px;
      font-weight: 300;
      font-size: 14px;
      z-index: 1;
      pointer-events: none;
    }
  }

  &.imui-text-field-stick-right input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &.imui-text-field-stick-left input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &.imui-text-field-has-icon input {
    padding-right: 40px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input,
  textarea {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffffd3 !important;
    border-radius: 6px;
    font-size: 14px !important;
    display: block;
  }

  textarea {
    padding: 6px 20px !important;
    margin-top: 0 !important;
    margin-bottom: -4px !important;
    height: unset;
    min-height: 42px;
  }

  &.imui-text-field-flat,
  &.imui-text-field-flatDark,
  &.imui-text-field-flatWhite {
    input,
    textarea {
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  &.imui-text-field-flatDark,
  &.imui-text-field-flatWhite {
    textarea,
    input {
      padding: 8px 8px !important;
      margin-top: 0 !important;
      margin-bottom: 0px !important;
      background-color: var(--greyish) !important;
    }

    &.imui-text-field-has-hint {
      /* hintText */
      > div:first-of-type {
        top: 8px;
        left: 8px;
      }
    }
  }

  &.imui-text-field-flatWhite {
    textarea,
    input {
      background-color: var(--white) !important;
    }
  }

  &.imui-text-field-border {
    input,
    textarea {
      border: 1px solid #e8eaed !important;
    }
  }

  &.imui-text-field-border-dark {
    input,
    textarea {
      border: 1px solid var(--grey) !important;
    }
  }
}

.imui-input-wrapper-fullwidth {
  width: 100%;
}

.imui-text-field-custom-icon {
  position: relative;
  width: 100%;
  height: 0;

  > * {
    position: absolute;
    right: 15px;
    top: -28px;
  }
}

@media print {
  .imui-text-field {
    textarea,
    input {
      padding: 8px 8px !important;
      margin-top: 0 !important;
      margin-bottom: 0px !important;
      background-color: transparent !important;
      border: 1px solid #e8eaed !important;
    }
  }
}
