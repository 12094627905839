.colorSelect {
  margin-bottom: 30px;
}
.swatchSquare {
  cursor: pointer;
}
.swatchBody {
  border: 5px solid #fff;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  touch-action: none;
}
.swatchInner {
  width: 36px;
  height: 36px;
  border: none;
  opacity: 0;
  touch-action: none;
}
