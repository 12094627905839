.tagEditorSidebar {
  height: 100%;
}

.doneLaterButtonsWrapper {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.sidebarSection {
  flex-shrink: 0;
  padding: 6px;

  &:last-child {
    border: 1px solid var(--light-grey);
  }
}

.sidebarTagsSection {
  flex-shrink: 1;
  position: relative;
  flex-grow: 1;
  flex-basis: 100%;
}

.dividerPetite {
  flex: 0 0 auto;
  margin: 0;
}
.sidebarFooter {
  margin: 0;
  padding: 4px;
  height: 46px;
  min-height: 46px;
  max-height: 46px;
  overflow: hidden;
  border-top: 1px solid var(--grey);
}

.ocrViewDisabled {
  color: rgba(0, 0, 0, 0.3) !important;
  pointer-events: none;
}

.ocrViewDisabled > div > div > span {
  color: rgba(0, 0, 0, 0.3) !important;
}
