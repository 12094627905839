.imui-radiobutton-group {
  &.imui-radiobutton-group-readonly {
    > div {
      cursor: not-allowed !important;
    }

    input {
      pointer-events: none !important;
    }
  }

  input + div > div {
    top: 3px;
  }

  label {
    font-family: averta, Arial, sans-serif !important;
    font-size: 14px;
  }

  svg {
    width: 17px !important;
    height: 17px !important;
  }
}
