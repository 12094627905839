.textItalic {
  font-style: italic;
}

.noMargin {
  margin: 0;
}

.spaceContainer {
  button + button {
    margin-left: 16px;
  }
}
