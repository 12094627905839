.filterSingle {
  align-items: center;
}

.actionIcon {
  margin-left: 8px;
  padding: 2px;
  color: var(--mid-grey);
  transition: color 0.3s;
}

.deleteIcon {
  &:hover {
    color: var(--red);
  }
}

.addIcon {
  color: var(--mid-grey);

  &:hover {
    color: var(--seafoam);
  }
}

.filterSingleActions {
  margin-bottom: 15px;
}

.filterSingleCondition {
  color: var(--soft-purple);
  margin-left: 8px;
}
