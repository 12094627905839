.axisValueTypeSelectorsExpanded {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.axisValueTypeSelectors {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.axisAttrSelctorTableWrapperExpanded {
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.axisAttrSelctorTableWrapper {
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}
.axisSelectorColumn {
  width: 50%;
}
.axisSelectorColumnX {
  width: 75%;
}
.axisSelectorColumnY {
  width: 25%;
  min-width: 200px;
}
.axisSwitchSeparator {
  width: 48px;
  margin-top: 24px;
  height: 42px;
  span {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
.buttonClass {
  border: 10px;
  width: 48px;
  height: 42px;
  background-color: #fff !important;
  border-radius: 6px !important;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
}
.axisAttrSelctorCollapsed {
  display: none;
}
.axisAttrSelctor button {
  font-size: 11px;
}
.axisAttrSelctor {
  border-radius: 0 0 6px 6px;
  padding-top: 10px;
  margin-top: -11px;
  overflow: hidden;
}
.axisAttrSelctorTable {
  width: 100%;
  border-collapse: collapse;
}
.axisAttrSelctorTable tr:nth-child(odd) {
  background: transparent;
}
.axisAttrSelctorTable tr:nth-child(even) {
  background: #ffffff;
}
.axisAttrSelctorTableItem {
  font-size: 10px;
  font-weight: 400;
  padding: 1px;
  cursor: pointer;
  padding-left: 16px;
  &:hover {
    background: #e0e0e0;
  }
}
.axisAttrSelctorTableItemCategory {
  font-size: 10px;
  font-weight: 600;
  border-top: 1px solid #e0e0e0;
  cursor: pointer;
  padding: 4px 4px 4px 4px;
  text-transform: uppercase;
  background: whitesmoke;
  &:hover {
    background: #e0e0e0;
  }
}
.axisAttrSelctorTableItemMetatag {
  font-size: 10px;
  font-weight: 600;
  border-top: 1px solid #e0e0e0;
  cursor: pointer;
  padding: 4px 4px 4px 4px;
  text-transform: uppercase;
  background: whitesmoke;
  &:hover {
    background: #e0e0e0;
  }
}
.axisAttrSelctorTableItemCategory[disabled] {
  opacity: 0.7;
  touch-action: none;
}
.axisAttrSelctorCheckbox {
  float: left;
  margin-top: -1px;
}
.axisAttrSelctorTableItemText {
  margin: 4px 4px 4px 34px;
  color: #262a2dbc;
  letter-spacing: 0.25px;
}
.valuesListTextButton {
  margin: 4px 0 0 4px;
}
.axisValuesFilter {
  padding-left: 8px;
  input {
    font-size: 12px !important;
    text-align: left;
  }
}
