.popoverMenuItem {
  font-family: averta, Arial, sans-serif !important;

  span[role='img'] {
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
  }

  > div > div > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.popoverMenuItemLeftIcon {
  > div > div {
    padding-left: 40px !important;
  }

  span[role='img'] {
    left: 12px !important;
  }
}

.popoverMenuItemRightIcon {
  > div > div {
    padding-right: 40px !important;
  }

  span[role='img'] {
    right: 12px !important;
  }
}

.popoverMenuItemLeftIcon.popoverMenuItemRightIcon {
  span[role='img'] {
    &:first-of-type {
      left: 12px !important;
      right: auto !important;
    }

    &:last-of-type {
      right: 12px !important;
      left: auto !important;
    }
  }
}

.popoverMenuPlaceholder {
  padding: 12px 20px;
  line-height: normal;
}
