.invitation-item-container {
  svg {
    flex: 0 0 auto;
    margin-bottom: 3px;
    cursor: pointer;
  }

  span {
    margin-right: 15px;
    margin-bottom: 3px;
  }
}

.invitation-item-input {
  flex: 1 0 auto;
  margin-right: 5px;
}

.space-right {
  margin-right: 20px;
}
