.surveyLanguageSelector {
  width: 100%;
  max-width: 184px;
}

@media print {
  .surveyLanguageSelector {
    display: none;
  }
}
