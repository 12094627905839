@import './index.module.css';

.importUploads {
  margin: 0;
}

.importUploadsTitle {
  margin-bottom: 10px;
}

.importUploadsItemIcon {
  font-size: 24px;
  display: inline-block;
  padding: 5px;
  position: relative;
  top: 3px;

  &:hover {
    color: var(--red);
  }
}

.sectionContent {
  flex-grow: 1;
}

.title {
  margin: 0;
}

.dropzoneContainer {
  min-height: 120px;
  max-height: 360px;
  display: flex;
  flex: 1;
  min-width: 120px;
}
.dropzoneAttachment {
  height: 24px;
  display: flex;
  flex: 1;
  width: 100%;
  text-wrap: nowrap;
  white-space: nowrap;
}
.dropzoneAttachment > div {
  line-height: 25px !important;
  margin-bottom: 0;
}
.dropzoneAttachment .icon-close {
  color: var(--red);
}
.dropzoneContainerCompleted {
  opacity: 0.7;
  touch-action: none;
}

.dropzone {
  flex-grow: 1;
}
.stickyFooter11 {
  z-index: 11;
}
.limitedWidth {
  margin: 0 auto;
  width: 100%;
}
