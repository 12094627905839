.userSettingsDivider {
  margin: 10px 0 30px;
}

.columnCentered {
  align-self: center;
}

.halfInput {
  width: 50%;
  padding-right: 10px;
}

.extendedLabel {
  white-space: nowrap;
  font-size: 14px !important;

  small {
    font-size: 12px;
    font-weight: 300;
    margin-left: 3px;
  }
}

.toggleContainer {
  align-items: center;
}

.mfaToggle {
  margin-right: 8px;

  label {
    color: var(--mid-grey) !important;
  }
}

.phoneInputDescription {
  width: 100%;
  display: block;
  color: var(--soft-grey);
  letter-spacing: 0.4px;
  font-size: 12px;
}
