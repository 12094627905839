.editorArea {
  position: relative;
  flex: 1 1 auto;
  flex-basis: 100%;
  transition: opacity 0.3s;
}

.editorWrapper :global(.textLayer::selection) {
  background: var(--highlight-color, #c1fa8b);
}

.editorWrapper {
  padding-right: 10px;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  background-color: transparent;
  border-radius: 6px;
}

@media (--tablet) {
  .tagEditorCardWrapper {
    gap: 20px;
    padding-top: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 10px;
  }
}

.tagEditorCardWrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
}

.tagEditorCard {
  height: 100%;
  background-color: transparent;
  position: relative;
}

.tagsInContext {
  background-color: var(--pale-grey);
  border-top: 2px solid var(--light-grey);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex-shrink: 0;
  padding: 6px;
  max-height: auto;
  overflow: scroll;
  label {
    float: left;
    font-size: 11px;
    margin-right: 4px;
    margin-bottom: 0px;
    color: var(--mid-grey);
    line-height: 1;
  }
  small {
    font-size: 11px;
    color: var(--dark-grey);
    display: inline-block;
    overflow-y: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: pre-line;
    font-style: italic;
    line-height: 1;
  }
  > div > div > div {
    text-align: left !important;
  }
}

.tagEditorFooter {
  margin: 0;
  padding: 4px;
  height: 46px;
  min-height: 46px;
  max-height: 46px;
  overflow: hidden;
  border-top: 1px solid var(--grey);
  background-color: var(--pale-grey);
}

:global .imHighlight > .Highlight,
:global .imHighlight > .Highlight > .Highlight__parts,
:global .imHighlight > .Highlight > .Highlight__parts > .Highlight__part {
  background-color: inherit;
  background-image: inherit;
}

:global .imHighlight > .Highlight > .Highlight__emoji {
  font-size: 14px;
}
