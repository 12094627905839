.surveyCustomSection {
  min-width: 0 !important;
}

.surveyCustomSectionSmall {
  padding: 20px;
}

.header {
  border-bottom: 1px solid #f4f6f9;
  padding-bottom: 32px;
  margin: 0;
  font-weight: bold;
  color: #262a2d;
  font-size: 36px;
  line-height: 1.2;
  text-align: center;
}

.subHeader {
  padding-bottom: 32px;
}

.subHeaderText {
  color: var(--soft-purple);
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-top: 0;
}

.logo {
  margin: 0 auto;
  display: block;
}

.surveyTitle {
  padding-bottom: 32px;
  margin: 0;
  font-weight: bold;
  color: #262a2d;
  font-size: 36px;
  line-height: 1.2;
  text-align: center;
}

.surveyToggle {
  label {
    width: auto !important;
    color: var(--soft-purple) !important;
    letter-spacing: 0.5px !important;
  }
}

.containerBody {
  position: relative;
  background-color: var(--white);
}

@media print {
  .subHeader {
    display: none;
  }

  .surveyTitle {
    font-size: 36pt;
    margin: 80pt 8pt 160pt;

    + * {
      page-break-before: always;
    }
  }
}
