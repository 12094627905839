.tabs {
  position: relative;
}

.tabItem {
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.titleTab {
  margin-top: 0;
}
.smallHeader {
  max-width: 96px;
}
