.imui-tooltip {
  color: #fff !important;
  background-color: #272e42 !important;
  font-size: 12px !important;
  font-family: averta, Arial, sans-serif !important;
  border-radius: 6px !important;
  letter-spacing: 0.4px !important;
  line-height: 14px !important;
  /* Bigger than popover */
  z-index: 1301 !important;
}
