.notificationsWidget {
  position: fixed;
  z-index: 1401;
  right: 40px;
  bottom: 24px;

  &.footerOffset {
    bottom: 98px;
  }

  .notificationBlip {
    min-width: 320px;
    max-width: 408px;
  }
}

.notificationBlip {
  position: relative;
  z-index: 1401;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px 24px;
  border-radius: 6px;
  min-width: 320px;
  max-width: 408px;
  min-height: 48px;
  font-size: 14px;

  + .notificationBlip {
    margin-top: 8px;
  }

  [class*='icon']:first-child {
    margin-left: -8px;
    margin-right: 16px;
  }

  .body {
    flex-grow: 1;
  }

  .title {
    font-size: 0.8em;
    margin-bottom: 4px;
  }

  .message {
  }

  .counter {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    background: inherit;
    box-shadow: 0px 2px 8px #0002;
  }

  &.stackTens .counter {
    font-size: 0.7em;
  }

  &.stackHundreds .counter {
    font-size: 0.6em;
  }

  .close {
    color: inherit !important;
    opacity: 0.6;
    justify-self: flex-end;
    margin-left: 16px;
    margin-right: -8px;
  }

  &.stackMore:after,
  &.stack:before {
    content: ' ';
    position: absolute;
    left: 3px;
    top: -3px;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: inherit;
    box-shadow: inherit;
    z-index: -1;
  }

  &.stackMore:after {
    left: 5px;
    top: -5px;
    z-index: -2;
  }

  &.floating {
    box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.15);
  }

  &.warning {
    background: #ffde8d;
    color: #262a2d;

    [class*='icon'] {
      color: #262a2d;
    }
  }

  &.error {
    background: #ffe4e4;
    color: #262a2d;

    [class*='icon'] {
      color: #fa3e3e;
    }
  }

  &.success {
    background: #e8ffe5;

    [class*='icon'] {
      color: #1b5a5a;
    }
  }

  &.info {
    background: #caf4ff;

    [class*='icon'] {
      color: #1b5a5a;
    }
  }
}
