.centeredSelf {
  align-self: center;
}

.leftSelf {
  align-self: flex-start;
}

.tilesContainer {
  margin: 0 -15px;
  justify-content: center;
}
