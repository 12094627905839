.tagEditorWaitingContainer {
  align-items: center;
  justify-content: center;
}

.tagEditorWaitingWrapper {
  width: 100%;
}

.tagEditorWaitingCard {
  height: 100%;
}
