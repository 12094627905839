.tableHeaderCollapsed {
  visibility: collapse;
}

.tagGroupRowBold {
  font-weight: bold;
}

.tagGroupRowHasChildren {
  background-color: #7c90a115;
  border-color: #7c90a115;
}

.metatagTagsList {
  margin: 0 -2px;

  > * {
    margin: 2px;
  }
}

.tagGroupRowIcon {
  display: inline-block;
  font-size: 16px;
  margin-right: 8px;
}

.tagGroupTag {
  color: var(--dark-blue) !important;
}

.tagGroupRowIconFirst {
  order: -1;
  margin: 0 14px 0 0 !important;
}

.isHidden {
  visibility: hidden;
}
