.imui-tile-header {
  background-color: var(--seafoam);
  color: var(--white);
  margin: -20px -20px 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 14px 32px;
  position: relative;

  @media (--tablet) {
    margin: -32px -32px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: inherit;
  }
}
