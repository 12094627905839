.expandableHeader {
  cursor: pointer;
}

.expandableHeaderIcon {
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer !important;
}

.expandableHeaderIconExpanded {
  transform: rotate(180deg);
}

.expandableHeaderIconRight {
  margin: 0 0 0 auto;
  order: 999;
}

.expandableList {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
