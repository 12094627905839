.imui-field-label {
  display: flex;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 18px;
  font-family: averta, Arial, sans-serif;
  font-weight: 300;
  color: #262a2d;
  margin-bottom: 7px;

  > span {
    margin-right: 10px;
    font-size: 16px;
  }
}

.imui-field-label-icon {
  color: var(--red);
  font-size: 7px !important;
  line-height: 1;
  margin-left: 4px;
  align-self: flex-start;
}
