.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

.tableCell {
  padding: 8px 6px;
}

.tableHeaderCell {
  font-weight: 600;
}
.tableHeader > tr > th:last-child {
  text-align: right;
}
.tableHeader > tr > th:nth-last-child(2):not(:first-child) {
  text-align: right;
}
.tableHeader > tr > th:first-child {
  text-align: left;
}

.tableHeader {
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.tableRow {
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: 0;
  }
}
