.ratingOptionGroup {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
}

.ratingOption {
  width: auto !important;

  > div > div {
    display: none !important;
  }

  label {
    width: 32px !important;
    font-size: 32px;
    color: #c5d1d9 !important;
    text-align: center;
    padding: 10px;
    box-sizing: content-box;
  }
}
.ratingReadonly {
  filter: greyscale(1);
}

.ratingEmpty {
}

.ratingSelected {
  label {
    color: #52cccc !important;
  }
}

.ratingDescription {
  width: calc(5 * 52px - 8px);
  margin-left: 4px;
  display: flex;
  margin-top: 16px;
  font-size: 12px;
  color: var(--mid-grey);
  justify-content: space-between;

  > * {
    flex: 0 0 40%;

    &:last-child {
      text-align: right;
    }
  }
}

@media (--mobile) {
  .ratingOptionGroup {
    justify-content: center;
  }

  .ratingDescription {
    width: auto;
    margin: 16px 8px 0 8px;
  }
}

:global .wrapper-mobile-styling {
  :local .ratingOptionGroup {
    justify-content: center;
  }

  :local .ratingDescription {
    width: auto;
    margin: 16px 8px 0 8px;
  }
}
