.grandContainer {
  height: 100%;
}

.grandContainer > div > div {
  /* Monkey patch solution to put absolute to
   * the relevant container div */
  position: absolute;
  width: 100%;
}

.grandContainer > div > div > div > div {
  margin: auto;
}

:global .cursorPointer {
  cursor: pointer !important;
}
