.actionsCell {
  font-size: 16px !important;
  text-align: center !important;
  color: #666666 !important;

  span + span {
    margin-left: 14px;
  }
}

.showOnHover {
  opacity: 0;
  transition: opacity 0.3s;
}

tr:hover .showOnHover {
  opacity: 1;
}

.cellSettingsContainer {
  justify-content: center;
}
