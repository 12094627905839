.condionalLogic {
  font-size: 14px;
  margin-top: 16px;
}

.baseOperator {
  line-height: 24px;
  display: flex;
  align-items: center;

  > * {
    margin: 0 8px;
    max-width: 250px;
  }
}

.conditionsList {
  margin: 8px 0;

  > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    > * {
      margin: 0 8px;
      max-width: 250px;
      margin-bottom: 8px;
    }

    &:first-child {
      margin-left: -8px;
    }
  }
}
