.actionButtons button {
  text-transform: uppercase;
  color: var(--soft-purple) !important;
  margin-right: 16px;
  font-weight: bold !important;
}

.actionButtonsDivider {
  margin: 0 0 30px;
}

.actionButton {
  align-items: center;

  span[role='img'] {
    order: 1;
    font-size: 16px;

    > div {
      text-transform: none;
    }
  }

  span:last-child {
    margin: 0 4px 0;
  }
}
