.imui-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  /* Firefox quirk */
  min-height: 0;

  @media print {
    display: block;
  }
}

.imui-container-centered {
  align-items: center;
}

.imui-container-anchor {
  position: relative;
}

.imui-container-content {
  > * {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.imui-container-horizontal {
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  .imui-container-content {
    > * {
      margin-bottom: 0px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.imui-container-center-horizontal {
  justify-content: center;
}

.imui-container-space-between {
  justify-content: space-between;
}

.imui-container-nowrap {
  flex-wrap: nowrap;
}

.imui-container-grow {
  flex-grow: 1;
}

.imui-container-max {
  overflow-y: auto;
}
