.settingSection {
  border-bottom: 1px solid #e3e9ee;
  padding: 20px 0;

  h3 {
    margin: 0 0 5px;
  }
}

.listLanguageCode {
  position: absolute;
  display: inline-flex;
  left: 16px;
}

span.listLanguageText {
  margin-left: 24px !important;
}

.languagesList {
  font-size: 14px;
  margin-top: 8px;
  color: var(--dark);

  > * {
    padding: 5px 0 0;

    &:hover button {
      display: block;
    }
  }

  div:first-child > svg:first-child {
    margin-right: 0;
  }

  > :first-child div:first-child > svg:first-child,
  > :last-child div:first-child > svg:nth-child(2) {
    opacity: 0.25;
  }

  svg {
    transition: none !important;
    flex-shrink: 0;
  }

  button {
    border: 0;
    background: transparent;
    display: none;
    cursor: pointer;
    padding: 0 0 0 0;
    color: var(--seafoam);
    outline: 0;

    &:disabled {
      color: var(--mid-grey);
      cursor: default;
      display: block;
    }
  }
}

.languageCompleteness,
.languageListActions {
  color: var(--mid-grey);
}

.disabledMoveIcon {
  display: none !important;
}

.disabledDeleteIcon {
  visibility: hidden;
  pointer-events: none;
}

.addLanguageButton {
  margin: 16px auto 0 0;
}

.languagesPopover {
  min-width: 352px !important;
  max-width: 352px !important;
}
