.questionPicker {
  margin: 16px 0;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}
.questionPickerBlockQuestion {
  max-width: 156px;
  width: 156px;
}
.questionPickerBlockLayout {
  max-width: 156px;
  width: 156px;
}
@media (min-width: 1700px) {
  .questionPickerBlockQuestion {
    position: fixed;
    left: 150px;
    top: 200px;
  }
}
@media (min-width: 1700px) {
  .questionPickerBlockLayout {
    position: fixed;
    left: 150px;
    top: 600px;
  }
}
.sectionHeader {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--soft-purple);
  margin-bottom: 16px;
}
