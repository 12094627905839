.swatch {
  border: 5px solid #fff;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}

.swatchColor {
  width: 36px;
  height: 14px;
  border-radius: 4px;
}

.swatchSquare {
  .swatchColor {
    height: 36px;
    border: none;
    opacity: 0;
  }
}

.pickerPopover {
  position: absolute;
  z-index: 2;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
}

.pickerPopoverMask {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
