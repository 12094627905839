.opinionScaleOption {
  color: var(--mid-grey);
  border: 1px solid var(--grey);
  transition: all 0.125s ease-in-out;

  &:hover {
    background: var(--soft-grey);
    border-color: var(--soft-grey);
    color: #fff;
  }

  &:first-child {
    border-radius: 6px 0 0 6px;
  }

  &:last-child {
    border-radius: 0 6px 6px 0;
  }

  > div > div {
    display: none !important;
  }

  label {
    width: 100% !important;
    font-size: 18px;
    text-align: center;
    color: currentColor !important;
    padding: 8px;
    box-sizing: content-box;
  }
}

.opinionScaleReadonly {
  filter: greyscale(1);
}

.opinionScaleOptionFilled {
  background: var(--soft-gray);
  border-color: var(--soft-grey);
  color: #fff;
}

.opinionScaleOptionSelected.opinionScaleOptionSelected {
  background: var(--seafoam);
  border-color: var(--seafoam);
  color: #fff;
}

.opinionScaleGroup {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin-top: 24px;
}

.opinionScaleDescription {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 16px;
  font-size: 12px;
  color: var(--mid-grey);

  > div {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.opinionScaleSelectFallback {
  display: block;
  text-overflow: ellipsis;
  align-self: flex-start;
  padding: 10px 6px;
  width: 100%;
  border-color: #e3e9ee;
  background: transparent;
  border-radius: 6px;
}

.selectEmpty {
  color: #94a4b2;
}

.opinionScaleSelectFallback {
  display: none;
}

@media (--mobile) {
  .opinionScaleSelectFallbackVisible {
    .opinionScaleSelectFallback {
      display: block;
    }

    .opinionScaleGroup,
    .opinionScaleDescription {
      display: none;
    }
  }
}

:global .wrapper-mobile-styling {
  :local .opinionScaleSelectFallbackVisible {
    .opinionScaleSelectFallback {
      display: block;
    }

    .opinionScaleGroup,
    .opinionScaleDescription {
      display: none;
    }
  }
}
