.contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;

  &.justifyEnd {
    justify-content: flex-end;
  }

  > div {
    flex-grow: 1;
    position: relative;
    height: 42px;
    background-color: #f0f2f5;
    color: #262a2d;
    border-radius: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px;
    max-width: 100%;
    overflow: hidden;
    align-items: center;
    display: block;
    line-height: 42px;

    > a {
      position: absolute;
      left: 16px;
      right: 16px;
      top: 0px;
      line-height: 42px;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: inherit;
      word-break: break-word;
    }
  }

  > :last-child {
    margin-left: 8px;
    cursor: pointer;
    cursor: copy;
    color: var(--mid-grey);
  }
}

div.styleSmall > div > a {
  font-size: 11px;
  letter-spacing: -0.25px;
}

.styleDarker {
  > div {
    background-color: #e3e9ee;
    color: #262a2d;
  }
}

.styleLighter {
  > div {
    background-color: #fff;
    color: #262a2d;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
  }
}
