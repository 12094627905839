.menu {
  width: 110px;
  position: fixed;
  left: 0px;
  background-color: #1e2333;
  height: 100%;
  color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.menu-disabled {
  pointer-events: none;
  opacity: 0.6;
}

@media print {
  .menu {
    display: none;
  }
}

.links {
  display: flex;
  background-color: #272e42;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  position: relative;
}

.list {
  cursor: pointer;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.bottomList {
  cursor: pointer;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #1e2333;
  color: #fff;
  padding-bottom: 16px;
}

.projectSwitcher {
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;

  > li {
    width: 100%;
  }
}

.logout {
  opacity: 0.6;
  transition: opacity 0.3s;
  margin: 20px auto;
  display: block;

  &:hover {
    opacity: 1;
  }
}
