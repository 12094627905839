.helpAppcuesTooltip {
  background-color: #33333b !important;
  color: #fafafa !important;
  padding: 14px 17px 15px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px hsla(0, 0%, 13%, 0.6) !important;

  font-size: 12px !important;

  &:after {
    height: 13px;
    width: 13px;
    background-color: #33333b;
    border-radius: 2px;
    transform: rotate(45deg);
    border: none !important;
  }

  &.place-top:after {
    margin-left: -6px;
  }

  &.show {
    opacity: 1 !important;
  }

  .multi-line {
    text-align: left;
  }
}
