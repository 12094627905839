.imui-card-empty {
  padding: 40px 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: #7c91a2;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.imui-card-empty-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  max-width: 75%;
}

div[class^='imui-section-grow'] > .imui-card-empty-title {
  margin: -1em auto 0.5em;
}

.imui-card-empty-large {
  .imui-card-empty-title {
    font-size: 32px;
    font-weight: normal;
    color: #82929f;
  }
  .imui-card-empty-description {
    font-size: 16px;
  }
}

.imui-card-empty-description {
  max-width: 75%;
  margin: 0 auto 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

* + .imui-card-empty-content {
  margin: 1em 0;

  h3 {
    margin: 40px auto;
    max-width: 75%;
    line-height: 1.3;
  }
}

.imui-card-empty-compact {
  padding: 10px 20px;
}

.owl {
  margin-top: 2em;
}
