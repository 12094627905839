.imui-status {
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 2px #82929f;
  margin: 0 auto;
}
.imui-status-done {
  border-color: #14cf6d;
  background-color: #14cf6d;
}
.imui-status-updated {
  border-color: #14cf6d;
  background-color: transparent;
}
.imui-status-disabled {
  border-color: #82929f;
  background-color: transparent;
}

.imui-status-alarming {
  border-color: #ea695e;
  background-color: transparent;
}
