.imui-tile-text {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.7px;
  color: #6b748f;
  text-align: center;
  padding: 0 50px;
  margin: 0 0 20px;
  font-weight: 300;
}

.large-tile-max-text-width {
  max-width: 550px;
  align-self: center;
}
