.editIcons {
  span {
    color: #666666;
    font-size: 13px;
  }

  span + span {
    margin-left: 16px;
  }
}
