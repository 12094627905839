.wrapper {
  margin: 0 auto;
  padding: 30px 20px 20px;

  @media (--tablet) {
    padding: 50px 20px 40px;
  }
}

.printAnswersButton {
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.inviteText {
  margin: 20px 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.7px;
  text-align: center;
  color: #6b748f;
}

.surveyTitle {
  margin: 0 0 56px;
  font-weight: bold;
  color: #262a2d;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;

  @media (--tablet) {
    font-size: 36px;
  }
}

@media print {
  .surveyTitle {
    font-size: 20pt;
  }
}

.subheader {
  display: flex;
  flex-flow: column wrap;
  line-height: 1;
  margin: 0 0 15px;
  padding-left: 20px;
  font-size: 22px;
  color: #7c90a1;
}

.granteeInfoSmaller {
  font-size: 16px;
  margin-bottom: 3px;
}

.errorMsg {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  color: #666666;
  line-height: 1.3;
  padding: 20px;
}

.startButton {
  display: inline-block;
}

.extraPaddingTop {
  padding-top: 24px;
}

.relEntitiesHeader {
  margin: 0 16px 32px;
  align-items: flex-start;

  > * {
    flex: 0 0 50%;
  }

  > :last-child {
    text-align: right;
    padding: 0 0 0 16px;
    flex: 0 0 50%;
  }

  > :first-child {
    text-align: left;
    padding: 0 16px 0 0;
  }

  > * > * {
    margin: 0 !important;
    padding: 0;
  }
}
