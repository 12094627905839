.companyLogoImg {
  margin: 24px auto;
  width: 40vw;
  height: 10vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  display: block;

  @media (--tablet) {
    width: 200px;
    height: 100px;
  }
}
