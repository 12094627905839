.languageSelector {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  > :first-child {
    margin-right: 10px;
  }
}

.listLanguageCode {
  position: absolute;
  left: 18px;
  top: 4px;
}
