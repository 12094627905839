.user-orgs-container {
  max-width: 900px;
  justify-content: space-between;
}

.user-orgs-list {
  margin: 0;
  list-style: none;
  padding: 0 0 0 10px;

  li + li {
    margin-top: 23px;
  }
}

.user-orgs-title {
  margin: 0 25px 0 0;
}

.empty-text {
  margin: 15px 15px 30px;
}

.user-orgs-card {
  margin-top: 10px;
  overflow: auto;
}
