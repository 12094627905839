.formButtonContainer {
  padding: 16px 0px;
}

.formButton {
  width: fit-content;
  align-self: flex-end;
}

.infoButton {
  font-size: 16px !important;
  display: inline-block;
}
