.wrapper {
  position: relative;
}

.content {
  overflow: auto;
  padding: 0;
  list-style-type: none;
}

.hidden {
  display: none;
}

.tagGroup {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  padding-bottom: 16px;

  &.toggled {
    padding-bottom: 10px;

    svg {
      transform: rotate(180deg);
    }
  }

  span {
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
  }
}

.item {
  margin: 0 0 16px;
  padding: 16px 8px;
  border: 1px solid var(--light-grey);
  border-radius: 6px;
  font-size: 14px;
  background-color: white;
}

.toggle {
  display: flex;
  justify-content: flex-end;
}

.header {
  margin: 16px 0;
}

.tableFooter > td {
  color: var(--mid-grey) !important;
}
