.indicatorIcon {
  font-size: 16px;
}

.doubleActions {
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
}

.noPadding {
  padding: 0 !important;
}

.alignedBaseline {
  align-items: baseline;
}
