.imui-divider {
  width: 100%;
  height: 1px;
  max-height: 20px;
  background-color: #e3e9ee;
  flex: 1 0 100%;
  margin: 20px 0;
}

.imui-divider-compact {
  margin: 0;
}

.imui-divider-small {
  margin: 10px 0;
}
