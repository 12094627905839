h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.5px;
  color: var(--dark);
  margin: 20px 0;

  @media (--tablet) {
    font-size: 36px;
  }
}

h2 {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.71;
  letter-spacing: 1px;
  color: #666666;
}

h3 {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.17;
  letter-spacing: 0.5px;
  color: var(--dark);
  margin: 16px 0;

  > [class^='icon'] {
    font-size: 16px;
    color: var(--mid-grey);
    display: inline-flex;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child:not(:first-child) {
      margin-left: 8px;
    }
  }
}

h4 {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.56;
  letter-spacing: 0.5px;
  color: var(--dark);
}

h5 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--mid-grey);
  margin: 20px 0;
}
