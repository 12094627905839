.imuiDrawer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: none;
}

.imuiDrawerContent {
  padding: 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
}

.imuiDrawerCloseContainer {
  position: relative;
}

.imuiDrawerIconClose {
  position: absolute;
  top: 0;
  right: 0;
}
