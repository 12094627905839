.chartName {
  margin-top: 0;
  font-weight: 600;
  letter-spacing: 0.5;

  i {
    font-style: normal;
    opacity: 0.6;
  }

  span {
    margin-left: 5px;
  }
}

.chartContent {
  width: 100%;
  max-width: clamp(1024px, 80vw, 1600px);
  margin: 0 auto;
  padding: 8px;
}

.dataChartWrapper {
  position: relative;
  margin: 10px;

  &:before {
    content: ' ';
    padding-top: 43.75%;
    display: block;
  }

  > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.toggleDataTableButton {
  background-color: #f4f6f9 !important;
  position: absolute !important;
  left: -16px;
  bottom: -16px;
  border-radius: 0 10px 0 0;
  padding: 15px 15px 13px;
}

.chartsPage {
  padding-bottom: 0;
}

.chartFooter {
  bottom: 0px !important;
  margin-bottom: 0;
}
