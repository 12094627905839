.warning {
  margin-bottom: 16px;
  padding: 16px 40px;
  font-size: 14px;
  color: var(--dark);

  & + .warning {
    margin-top: -8px;
  }
}

.warning-caution {
  background-color: var(--lemon-tart);
}

.warning-error {
  background-color: rgba(250, 62, 62, 0.15);
  border: 1px solid var(--red);
}

.warningCompact {
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
}

.warningIcon {
  margin-right: 16px;
}

.warningIgnore {
  font-size: 14px !important;
  margin-left: auto;
}
