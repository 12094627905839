.selectPopoverLight {
  position: relative;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
  line-height: 40px;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  min-width: 150px;

  span:first-child {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span:last-child {
    margin-left: 10px;
  }
}

.selectPopoverLight_flat {
  box-shadow: none;
  background-color: transparent;
}

.selectPopoverLight_largeWidth {
  width: 500px;
  span:first-child {
    max-width: 500px;
  }
}

.selectPopoverLight_border {
  border: 1px solid #e8eaed;
}

.selectPopoverLight_borderDark {
  border: 1px solid var(--grey);
}

.selectPopoverLight_disabled {
  cursor: not-allowed;
}

.selectPopoverLight_small {
  height: 36px;
  line-height: 36px;
  max-height: 36px;
}

.selectPopoverLightHint {
  color: #b4c1cc;
  font-weight: 300;
}

.selectPopoverLightOverlay {
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.3);
  top: 42px;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

.selectPopoverLightProgress {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
}

.selectPopoverLightTip {
  color: var(--mid-grey);
  font-size: 12px;
  font-style: italic;
  margin-left: 16px;
}
