.statusIndicator {
  display: flex;
  align-items: center;
  align-self: flex-start;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0;
  background-color: transparent;
  overflow: hidden;

  font-size: 12px;
  letter-spacing: 0.5px;

  &:hover .statusIndicatorText {
    transform: translateX(0);
  }

  &:hover span[role='img']:after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    width: 2px;
    height: 100%;
    display: block;
    background-color: inherit;
    z-index: 2;
  }

  span[role='img'],
  .statusIndicatorText {
    color: var(--soft-purple);
    background-color: var(--white);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
  }

  span[role='img'] {
    font-size: 16px;
    line-height: 1;
    padding: 5px 4px;
    cursor: pointer !important;

    position: relative;
    z-index: 1;
  }
}

.statusIndicatorAlert {
  span[role='img'],
  .statusIndicatorText {
    color: var(--white);
    background-color: var(--red);
    box-shadow: none;
  }
}

.statusIndicatorInfo {
  span[role='img'],
  .statusIndicatorText {
    color: var(--dark);
    background-color: #caf4ff;
    box-shadow: none;
  }
}

.statusIndicatorSuccess {
  span[role='img'],
  .statusIndicatorText {
    color: var(--white);
    background-color: var(--green);
    box-shadow: none;
  }
}
.statusIndicatorWarning {
  span[role='img'],
  .statusIndicatorText {
    color: var(--red);
  }
}

.statusIndicatorText {
  height: 100%;
  position: relative;
  padding: 5px 6px 5px 2px;
  line-height: 16px;
  white-space: nowrap;

  transition: all 0.3s;
  transform: translateX(-100%);
}
