.popoverContent {
  padding: 20px;
  font-size: 14px;
  line-height: 1.57;
  color: var(--dark);

  p {
    margin: 0;
  }
}

.popoverContentActions {
  margin-top: 25px;

  button + button {
    margin-left: 15px;
  }
}
