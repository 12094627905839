.filterItem {
  max-width: 190px;
  flex: 1 0 190px;
}
.filterItemResult {
  max-width: 350px;
  flex: 1 0 300px;
}

.filterItem + .filterItem {
  margin-left: 8px;
}
.filterItem + .filterItemResult {
  margin-left: 8px;
}
