.reviewPage {
  @media print and (color) {
    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }
}
.responseHeaderMediaDisplayNone {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 21;
  @media print {
    display: none;
  }
}

.reviewHead {
  margin-bottom: 40px;
}

.surveyTitle,
.relEntitiesHeader {
  margin-bottom: 20px;
}

.reviewHeadingLabels span {
  color: var(--dark-grey);
  font-size: 16px;
  line-height: 1.5;
}

.surveyTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 0.5px;
  color: var(--dark);
}

.saveStatusBar {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  width: 100%;
}
.saveStatus {
  font-size: 14px;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #7c91a2;
  place-self: center;
  strong {
    font-weight: bold;
    color: #52cccc;
  }
}

.relEntitiesHeader {
  font-size: 14px;
  line-height: 18px;
  color: var(--dark);

  > div + div {
    margin-top: 8px;
  }
}

.surveyDetails {
  display: flex;
  flex-direction: row;

  > div {
    margin-left: 8px;
  }
}
