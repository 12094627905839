@import 'normalize.css';
@import './Typography.css';

/*
 color changes to the ones we have in zeplin for two base greyish colours
 we had to make them 10% darker for readability on worse screens

 b4c4d1 => 93aabd
 9aabb8 => 7c91a2
*/

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  height: auto;
}

body,
#root,
#root > div {
  min-height: 100%;
  height: 100%;
}

a {
  color: var(--seafoam);
  text-decoration: none;
}

a,
button,
input,
textarea,
select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: averta, Arial, sans-serif;
  color: var(--dark);
  background-color: var(--pale-grey);
  -webkit-font-smoothing: antialiased;
}

.app {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  height: 100%;
}

.loader {
  position: absolute;
  top: 0px;
  left: 0;
  height: 6px;
  background: #14cf6d;
  z-index: 9999;
}

.layoutContent > div {
  position: absolute;
  left: 110px;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.blocked {
  button {
    opacity: 0.7 !important;
    pointer-events: none !important;
  }
  input {
    pointer-events: none;
  }
}

.btn-primary {
  border: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-family: averta, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  transform: translate(0px, 0px);
  position: relative;
  height: 36px;
  line-height: 36px;
  width: 100%;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background-color: #52cccc;
  text-align: center;
}

:global(.printVisible) {
  visibility: hidden !important;
  overflow: hidden !important;
  height: 0 !important;
}

:global .mt-1 {
  margin-top: 1rem;
}

@media print {
  @page {
    margin: 2cm;
    size: A4 portrait;
  }

  html,
  body,
  #root,
  #root > div {
    min-height: 0;
    height: auto;
    background-color: #fff;
  }

  .app {
    display: block !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }

  table,
  figure {
    page-break-inside: avoid;
  }

  .layoutContent > div {
    position: static;
    left: 0px;
  }

  :global(.printHidden) {
    display: none;
  }

  :global(.printVisible) {
    visibility: visible !important;
    height: auto !important;
  }
}

@page {
  size: auto;
  margin: 20mm 10mm 10mm 10mm;
}
