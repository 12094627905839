.answerSurveySuccessHeader {
  margin: 0 0 16px;
}

.answerSurveySuccessText {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--soft-purple);
  margin-top: 0;
  max-width: 30em;
  text-align: center;
}
