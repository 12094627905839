.dataFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.dataFilterItems {
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  align-self: center;
  flex-wrap: wrap;

  > * {
    margin: 2px;
  }
}

.checkboxSelectAllWrapper {
  padding: 0 16px 8px;
  opacity: 0.6;
  border-bottom: 1px solid #e3e9ee;
}

.checkboxSelectAll {
  label {
    font-size: 12px !important;
  }
}
