@import './data/styles.css';

.imui-icon {
  line-height: inherit;
  transition: color 0.3s;

  &:before {
    vertical-align: bottom;
    line-height: inherit;
  }
}

.imui-icon-error {
  color: var(--red);
}

.imui-icon-disabled {
  color: var(--grey);
  opacity: 0.6;
  cursor: default !important;
}

.imui-icon-clickable {
  cursor: pointer;
}
