.title {
  display: flex;
  justify-content: space-between;
}

.quotesExportIcon {
  display: inline-block;
  color: var(--mid-grey);
  padding: 2px;
}

.quotesExportIconList {
  margin: 0 0 12px auto;
}

.quotesExportIconTitle {
  margin-left: 12px;
}

.quotesList {
  max-height: auto;
  overflow: auto;
  padding-right: 15px;

  a {
    color: inherit;
  }
}

.quote {
  font-style: italic;
  line-height: 1.71;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #262a2d;
  margin-bottom: 20px;
}

.quoteText {
  padding-left: 10px;
  margin: 0;
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
}

.quoteOrigin {
  text-align: right;
  font-weight: 500;
}

.footer {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.toggle {
  margin: 0 8px 0 2px;
}
