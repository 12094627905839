.overviewItem {
  border-bottom: 1px solid #e3e9ee;
  padding: 24px 0 16px;
  color: var(--mid-grey);

  &:last-child {
    border-bottom: none;
  }

  h3 {
    margin: 0 0 5px;
  }
}

.hasToggle {
  align-items: flex-start;
}

.extraSpace {
  margin-top: 8px;
}
