.settingsWrapper {
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  gap: 10px;
  margin-bottom: 10px;
}
.settingBox {
  flex: 1 0 0;
}
.boxHeader {
  flex-grow: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0px;
  margin: 0;
  color: #262a2d;
  display: flex;
  align-items: center;
  max-width: 80px;
  width: 80px;
}
.boxContent {
  color: #7c90a1;
  line-height: 1.4;
  display: flex;
  align-items: center;
  flex-grow: 1;
  place-self: flex-end;
  justify-content: center;
}
.boxTop {
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.boxTop + * {
  margin-top: 14px;
}
.boxCenter {
  flex-grow: 1;
  align-self: flex-end;
}
.boxToggle {
  > div > div {
    margin-left: 0 !important;
  }
}

.datePickerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  place-self: flex-end;
  justify-content: center;
  color: #7c90a1;

  > :first-child,
  > :last-child {
    align-self: center;
  }

  svg {
    height: 16px;
    cursor: pointer;
  }
}

.boxDatePicker {
  > div {
    width: auto !important;
    margin: 0 8px !important;
  }

  input {
    width: 120px !important;
    text-align: center !important;
    box-shadow: none !important;
    padding: 0 !important;
    color: #262a2d !important;
    background: #f0f2f5 !important;
  }
}

.subAction {
  margin-top: 10px;
}

.clickableField input {
  cursor: pointer !important;
}
