.tagDetailItemDivider {
  margin: 5px 0 20px;
}

.tagDetailItemTagNameWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.tagDetailItemTagNameField {
  flex: 1;
}

.tagDetailItemPopover {
  &:not(.imui-popover-custom-width) {
    min-width: 190px !important;
  }
}
