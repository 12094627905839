.listItem {
  list-style: none;
  margin-bottom: 4px;
  padding-left: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.listItemTextWrapper {
  max-width: 100%;
  min-height: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 11px;

  &:hover .listRightIcons {
    opacity: 1;
    pointer-events: auto;
  }
}

.listItemText {
  margin-right: 16px;
  flex: 0 1 auto;
  cursor: default;
}

.clickable {
  cursor: pointer;
}

.listRightIcons {
  flex: 0 0 auto;
  opacity: 0;
  pointer-events: none;
  margin-top: 3px;
  align-self: flex-start;
}

.tagInContextHighlight {
  display: inline-block;
  padding: 3px;
  border-radius: 2px;
  transition: background-color 100ms;
}

.tagToolTip {
  max-width: 400px;
  text-align: justify;
}
