.dataSourcesHeader {
  margin: 0 0 24px;
  max-width: 800px;
  min-width: 580px;
}

.dataSourcesContainer {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
}

.dataSourcesTile {
  max-width: 800px;
  min-width: 580px;
  padding: 0px;
  margin-bottom: 24px;
  border-bottom: none;
}

.inputContainer {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);
}

.sourceHeader {
  border-width: 0px 0px 1px 0px;
  border-style: dashed;
  border-color: #c5d1d9;
  padding-bottom: 24px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sourceLogo {
  height: 32px;
  width: auto;
  padding-right: 8px;
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-direction: row;

  font-size: 18px;
  font-weight: bold;
}

.oAuthFieldLabel {
  font-size: 14px !important;
}

.buttonContainer {
  justify-content: flex-end;
}

.formButtonContainer {
  align-items: self-end;
}

.formButton {
  width: fit-content;
}
