.imui-actions {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin: 0 8px 0 0;
  }

  &.imui-actions--small {
    > * {
      margin: 2px 8px 2px 0;
    }
  }

  > *:last-child {
    margin-right: 0;
  }

  &.imui-actions--nowrap {
    flex-wrap: nowrap;
  }
}

.imui-actions + .imui-actions,
* + .imui-actions:last-child {
  justify-content: flex-end;
  margin-right: 0;

  > * {
    margin: 0 2px 0 6px;

    @media (--tablet) {
      margin: 0 0 0 15px;
    }
  }

  &.imui-actions--small {
    > * {
      margin: 2px 0 2px 8px;
    }
  }
}

.imui-actions--grow {
  flex: 1;
}
.imui-actions--nogrow {
  flex: 0;
}

.imui-actions--equal-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 15px;

  > * {
    margin: 0;
  }
}
