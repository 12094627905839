.filterTrigger {
  width: 42px;
  height: 42px;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  border: 2px solid transparent;
  background-color: #ffffff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
  outline: 0;
  cursor: pointer;

  margin-right: 8px;
  color: var(--mid-grey);

  transition:
    color 0.2s,
    border-color 0.3s,
    background-color 0.3s;
}

.filterTriggerApplied {
  color: var(--seafoam);
  border-color: var(--seafoam);
}

.filterTriggerActive {
  color: var(--white);
  border-color: var(--seafoam);
  background-color: var(--seafoam);
}

.filterTriggerIcon {
  font-size: 20px;
  line-height: 38px;
  cursor: pointer !important;
}
