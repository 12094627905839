.tabSegment {
  cursor: pointer;
  width: 100%;
  min-width: 140px;
  max-width: 140px;

  height: 100%;
  min-height: 42px;
  max-height: 42px;
  padding: 4px 12px;

  text-align: center;
  font-size: 14px;
  color: #221e20;
  position: relative;
  transition:
    background-color 0.3s,
    border 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  /* last-child, last-of-type broken because of div from a tooltip */
  &.tabSegment-first {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }

  &.tabSegment-last {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }

  > span {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.tabSegmentActive {
  font-weight: 600;
  border: solid 1px #dae1e6 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  position: relative;
  cursor: default !important;
  background-color: transparent !important;

  &.tabSegment-first {
    border-left: solid 1px #dae1e6 !important;
  }

  &.tabSegment-last {
    border-right: solid 1px #dae1e6 !important;
  }
}

.tabSegmentInactive {
  background-color: #fff;

  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }

  &.tabSegment-first {
    box-shadow:
      -5px -5px 15px -5px rgba(0, 0, 0, 0.07),
      0 5px 15px -5px rgba(0, 0, 0, 0.07);
  }

  &.tabSegment-middle {
    box-shadow:
      0 5px 15px -5px rgba(0, 0, 0, 0.07),
      0 -5px 15px -5px rgba(0, 0, 0, 0.07);
  }

  &.tabSegment-last {
    box-shadow:
      5px -5px 15px -5px rgba(0, 0, 0, 0.07),
      0 5px 15px -5px rgba(0, 0, 0, 0.07);
  }
}

.tabSegmentCompact {
  min-height: 0;
  max-height: none;

  min-width: 0;
  max-width: none;

  /* icon glitch */
  padding: 11.5px 12px;
  line-height: 1.1;
}

.tabSegmentFlat {
  box-shadow: none;

  &.tabSegment-first {
    box-shadow: none;
  }

  &.tabSegment-middle {
    box-shadow: none;
  }

  &.tabSegment-last {
    box-shadow: none;
  }
}

.tabSegmentDark.tabSegmentInactive {
  background-color: #fff;
  border: 1px solid #fff;
  color: var(--mid-grey);
}

.tabSegmentDark.tabSegmentActive {
  background-color: #7c90a1 !important;
  border: 1px solid #7c90a1 !important;
  color: #fff;
}

.tabSegmentBorder {
  &.tabSegmentInactive {
    border-top: 1px solid var(--grey);
    border-bottom: 1px solid var(--grey);
  }

  &.tabSegment-last {
    border-right: 1px solid var(--grey);
  }

  &.tabSegment-first {
    border-left: 1px solid var(--grey);
  }
}
