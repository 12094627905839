.successful-screen-container {
  justify-content: center;
}

.successful-screen-card {
  max-width: 520px;
  align-items: center;
  margin: 0 auto 40px;
  text-align: center;
  line-height: 1.5;
}

.successful-screen-owl-container {
  display: flex;
  justify-content: center;
}

.successful-screen-text {
  color: #93aabd;
  font-weight: 300;
}

.successful-screen-action {
  margin: 20px 0;
}

.altAction {
  color: #52cccc;
  border: 0;
  font-size: 0.8em;
}
