.warningDialogError {
  color: var(--red) !important;
}

.warningDialogList {
  padding-top: 16px;

  li {
    margin-bottom: 8px;
  }
}
