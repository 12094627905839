.alert {
  padding: 20px;
  background-color: #ffd394;
  border-bottom: solid 1px #ffbf66;
  text-align: center;
}

.alertLink {
  color: var(--dark);

  > :first-child {
    margin-right: 8px;
  }
}

.closebtn {
  margin-left: 15px;
  margin-top: -3px;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
  color: #333333;
}

.closebtn:hover {
  font-weight: bold;
  color: #000000;
}

@media print {
  .alert {
    display: none;
  }
}
