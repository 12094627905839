.imui-tile-img {
  margin-top: 20px;

  &.imui-tile-img-size-m {
    height: 64px;
  }

  &.imui-tile-img-size-l {
    height: 100px;
  }
}

.imui-tile-background-img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}
