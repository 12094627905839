.tooltipName {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 4px;
  padding: 0;
}

.tooltipClickInfo {
  margin-top: 4px;
}

.highcharts-name-international {
  pointer-events: bounding-box;
}
