.filterCreator {
  background-color: var(--white);
  padding: 24px 40px;
  margin-bottom: 24px;
}

.filterCreatorFullWidth {
  margin-left: -40px;
  margin-right: -40px;
}

.filterCreatorHeader {
  color: var(--soft-grey);
  font-size: 14px;
  margin: 0 0 8px;
  font-weight: normal;
}

.filterCreatorActions {
  padding-top: 8px;

  button + button {
    margin-left: 8px;
  }
}
