@import './TagListItem.module.css';

.subList {
  padding: 0;
  margin-top: 0px;

  .listItem {
    margin-bottom: 0;
    padding-left: 24px;
  }
}

.listItem {
  padding-left: 0;
}

.listLeftIcon {
  flex: 0 0 auto;
  cursor: pointer !important;
  font-size: 16px;
  margin-right: 8px;
  color: #666666;

  transition: all 0.3s;
}

.listIconExpanded {
  transform: rotate(-180deg);
}

.listItemCheckbox {
  margin-right: 8px;
}
