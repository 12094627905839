.manage-subjects-demonstration-container.blocking-style {
  background-repeat: no-repeat;
  background-position: center top;
  height: 800px;
  flex: none;
}

.MANAGE_SUBJECTS {
  background-image: url(/src/static/manageSubjects.jpg);
}

.REPORTS {
  background-image: url(/src/static/Reports.jpg);
}

.GRANTS {
  background-image: url(/src/static/Grants.jpg);
}

.CHARTS {
  background-image: url(/src/static/Charts.jpg);
  height: 1000px !important;
}

.tile-container {
  margin-top: 48px;
}

.survey-only-icon {
  margin-top: 90px !important;
}

.demonstration-card-gold-pro {
  background-color: #ffde8d;
  font-size: 9px;
  padding: 3px 6px;
  margin-left: 5px;
  border-radius: 2px;
  position: absolute;
  top: -8px;
}

.demonstration-card-button {
  color: #e0961d !important;
  border-color: #e0961d !important;
  align-self: center;
  margin-bottom: 90px;
}
