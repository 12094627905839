@media print {
  .footer {
    display: none !important;
  }
}

.footer {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  padding: 10px 20px;
  margin: 10px 0 0;
  border-top: 1px solid var(--light-grey);
}

.footerChild {
  width: 100%;
  margin: 0 auto;
  display: flex;

  @media (--tablet) {
    justify-content: flex-end;
    align-items: center;
    flex-flow: row nowrap;
  }

  > * {
    flex: 1;
  }
}

.footerChildMaxWidth {
  max-width: 720px;
}

.footerFixed {
  position: fixed;
  max-height: 64px;
  @media (max-width: 600px) {
    padding: 8px;
  }
}

.footerChildMobile {
  display: flex;
  flex-flow: column wrap;
  align-items: unset;
}

.noAction {
  pointer-events: none;
}

.spaceBottom {
  padding-bottom: 0;
}

.buttonLast {
  margin: 0 0 0 8px !important;
}

.centeredAction {
  justify-content: center !important;
}

.statusText {
  font-size: 12px;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--soft-purple);

  @media (--tablet) {
    font-size: 16px;
  }
}

.terminationMessage {
  letter-spacing: 0.25px;
  color: #fa3e3e;
  text-align: center;
  flex: 5 1;
  @media (--tablet) {
    font-size: 16px;
  }
}
