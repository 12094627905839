.analysisInputs {
  width: 100%;
  max-width: 600px;
  margin-right: auto;
}

.analysisInputsRadios {
  display: block;
  padding: 20px 0;
  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

.analysisInputsControls {
  text-align: center;
  padding: 20px 0 20px;
}

.paddingDownSmall {
  padding-bottom: 5px !important;
}
