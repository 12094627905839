.imui-tag {
  max-width: 100%;
  min-height: 21px;
  border-radius: 6px;
  line-height: 1.33;
  border: 0;
  padding: 0;
  background-color: #7c90a1;
  outline: 0;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &.imui-tag-inline {
    display: inline-flex;
  }
}
.imui-tag-dark {
  background-color: #7c90a1;
}
td .imui-tag {
  display: inline-flex;
}
.imui-tag-inline {
  display: inline-flex;
}
.imui-tag-positive {
  background-color: #72d65c;
}
.imui-tag-informative {
  background-color: #7ab4ee;
}
.imui-tag-unstable {
  background-color: #faa93e;
}
.imui-tag-alt {
  color: var(--dark);
  background-color: var(--lemon-tart);
  cursor: pointer;
  .imui-tag-icon {
    color: var(--dark);
  }
}
.imui-tag-warning {
  background-color: var(--red);
}
.imui-tag-grey {
  background-color: #7c90a1;
  color: #fff;
  &.imui-tag-grey:hover {
    background-color: var(--dark-grey);
  }
}
.imui-tag-dim {
  background-color: #f0f2f5;
  color: #2e2f40;
}
.imui-tag-disabled {
  background-color: #e3e9ee;
  color: #2e2f40;
}
.imui-tag-inherit {
  background-color: currentColor;
  color: inherit;
  .imui-tag-label {
    color: #f4f6f9;
  }
}
.imui-tag-dashed {
  background-color: transparent;
  &.imui-tag:hover {
    background-color: inherit;
  }
  color: inherit;
  border-color: currentColor;
  border-style: dashed;
  &.imui-tag-warning {
    border-color: var(--red);
    .imui-tag-label {
      color: var(--red);
    }
  }
}

.imui-tag-outline {
  background-color: transparent;
  color: var(--mid-grey);
  font-weight: 600;
  &.imui-tag-outline:hover {
    color: var(--dark-grey);
  }
  border-color: #99aab8;
  &.imui-tag-alt {
    background-color: var(--lemon-tart);
    cursor: pointer;
    .imui-tag-icon {
      background-color: var(--lemon-tart);
    }
  }
  &.imui-tag-warning {
    color: var(--red);
  }
}
.imui-tag-label {
  min-height: 20px;
  font-family: averta, Arial, sans-serif;
  font-size: 11.5px;
  line-height: 16px;
  letter-spacing: 0px;
  margin: 0 6px;
  padding: 2px 0px;
  text-align: center;
  gap: 4px;
  display: flex;
}
.imui-tag-leftAlign .imui-tag-label {
  text-align: left;
}
.imui-tag-icon {
  font-size: 17px;
  line-height: 20px;
  max-height: 21px;
  min-width: 0px;
  flex-shrink: 0;
  color: var(--white);
  position: relative;
  padding: 0px;
}
.imui-tag-dim {
  .imui-tag-icon {
    color: #afafaf;
  }
}
.imui-tag-small {
  .imui-tag-label {
    font-size: 10px;
    margin: 0 7px;
    padding: 3px 0px;
  }
  max-height: 18px;
  min-height: 18px;
}
.imui-tag-squished {
  border-radius: 20px;
  .imui-tag-label {
    padding: 0 2px;
    margin: 0 6px;
    line-height: 1.33;
    color: var(--soft-purple);
  }
}
.imui-tag-square {
  border-radius: 0;
}
.imui-tag-borderGrey {
  border: 1px solid var(--grey);
}
