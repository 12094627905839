.publicSurveyTitle {
  margin: 0 0 16px;
  text-align: center;

  font-size: 24px;
  line-height: 1.2;

  @media (--tablet) {
    font-size: 32px;
  }
}

.publicSurveyTitleExpired {
  color: var(--soft-purple);
}

.publicSurveyText {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--soft-purple);
  text-align: center;
  margin: 0 0 16px;
}

.publicSurveyTextAdditional {
  font-size: 14px;
  font-style: italic;
  line-height: 1.71;
  letter-spacing: 0.4px;
  color: var(--soft-purple);
  margin-bottom: 24px;
}
