.analysis-type-card-single-inactive {
  opacity: 0.5;
}

.planContainer {
  margin: 0 auto;
  flex-direction: row;
  margin-bottom: 36px;
}

.planTilesContainer {
  display: flex;
}

.planTile {
  flex: 1;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
}

.planTitle {
  margin-bottom: 8px;
}

.planSubtitle {
  margin: 0;
  color: var(--mid-grey);
}

.planTile-active {
  opacity: 1;
  border: var(--seafoam) 2px solid;
}

.planTile-inactive {
  opacity: 0.4;
}

.planCustomizer {
  background-color: transparent;
  border: none;
  padding-left: 0px;
}

.planTabs > div > button div {
  font-size: 12px;
  font-weight: 700;
}

.baseSubscriptionDescription {
  font-size: 12px;
  color: var(--mid-grey);
}

.paymentIntervalContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentIntervalContainer > h3 {
  font-weight: 700;
}

.paymentIntervalSelector {
  width: 140px;
}

.paymentIntervalSelector > div {
  font-size: 10px;
}

.planSection {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.planSection > div {
  display: flex;
  flex-direction: column;
}

.addOnsContainer {
  min-height: 240px;
}

.addOns > div {
  display: flex;
  flex-direction: column;
}

.aiTranslationAndInsightsAddOn {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.aiTranslationAndInsightsAddOn > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.aiTranslationAndInsightsAddOn > div h5 {
  margin-top: 0;
  width: 200px;
}

.aiTranslationAndInsightsAddOn > div h5 > span {
  background-color: var(--lemon-tart);
  font-size: 10px;
  text-decoration: italic;
  padding: 2px 4px;
}

.aiTaggingAddOn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aiTaggingAddOn > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aiTaggingAddOn > div h5 {
  margin: 0;
  width: 200px;
}

.userAddOn {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.userAddOn > div {
  display: flex;
  gap: 14px;
  min-width: 60px;
  justify-content: flex-end;
}

.userAddOn > div > div:last-child {
  width: 100px !important;
  margin-right: 12px;
}

.totalContainer {
  padding: 0;
  margin-top: 80px;
  background-color: transparent;
  width: 280px;
}

.totalContainer > div > p {
  padding: 16px;
  color: var(--soft-grey);
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}

.totalBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, var(--seafoam), var(--sky));
  height: 400px;
  color: white;
  text-align: center;
}

.totalBanner > div > p {
  font-weight: 700;
  font-size: 12px;
}

.totalBanner h3 {
  color: white;
  font-weight: 700;
  font-size: 32px;
}

.totalBanner button {
  background-color: white;
  color: black;
  border: solid 2px var(--ocean);
  margin: auto;
  margin-top: 20px;
}

.totalBanner button:hover {
  background-color: white;
}

.customPlanLink {
  color: white;
}

.trainingContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0 auto;
  margin-bottom: 36px;
}

.trainingCard {
  width: 280px;
  padding: 0;
}

.trainingCardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, var(--seafoam), var(--sky));
  height: 400px;
  color: white;
  text-align: center;
}

.trainingCardContent > div > p {
  font-weight: 700;
  font-size: 12px;
}

.trainingCardContent h2 {
  color: white;
  font-weight: 700;
}

.trainingCardContent h3 {
  color: white;
  font-weight: 700;
  font-size: 24px;
}

.trainingCardContent button {
  background-color: white;
  border: solid 2px var(--ocean);
  color: black;
  margin: auto;
  margin-top: 20px;
}

.trainingCardContent button:hover {
  background-color: white;
}

.trainingCardContent a {
  background-color: white;
  border: solid 2px var(--ocean);
  color: black;
  margin: auto;
  margin-top: 20px;
}

.trainingCardContent a:hover {
  background-color: white;
}

.postCheckoutImage {
  display: flex;
  justify-content: center;
}

.currentSubscriptionBadge {
  position: absolute;
  right: 10px;
  top: 10px;
  background: var(--soft-grey);
  color: white;
  font-size: 14px;
  padding: 4px;
}
