@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.highcharts-loading-inner {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 6px solid #52cccc;
  border-right-color: transparent;
  border-radius: 50%;
  color: transparent;
  animation: rotate-forever 1s ease-in-out infinite;
}
