.imui-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
  /* Firefox quirk */
  min-height: 0;
}

@media print {
  .imui-card {
    padding: 10pt;
    border: 1px solid #efefef;
    box-shadow: none;
  }
}

.imui-card-large-padding {
  @media (--tablet) {
    padding: 32px;
  }
}
.imui-card-normal-padding {
  padding: 15px;
}
.imui-card-small-padding {
  padding: 8px;
}

.imui-card-no-padding {
  padding: 0;
}

.imui-card-expanded {
  margin-left: -40px;
  margin-right: -40px;
  border-radius: 0;
  border: 1px solid #e3e9ee;
  border-width: 1px 0px;
}

.imui-card-grow {
  flex-grow: 1;
}

.imui-card-anchor {
  position: relative;
}

.imui-card-flat {
  box-shadow: none;
}

.imui-card-border {
  border: solid 1px var(--grey);
}
