.statsWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.statsItem {
  color: var(--dark);
  background-color: var(--white);
  font-weight: 300;
  flex: 1 0;
  flex-basis: calc(20% - 10px);
  border: 1px solid var(--soft-grey);
  transition: border-color 0.2s;
  padding: 12px;
  border-radius: var(--default-radius);
}
.statsItemClickable:hover {
  cursor: pointer;
  border-color: var(--seafoam);
}
.statsItemValueWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.statsItemTitle {
  line-height: 1.15;
  font-size: 20px;
  font-weight: 400;
}
.statsItemValue {
  line-height: 1.15;
  font-size: 44px;
}
.statsItemDescr {
  line-height: 1.15;
  font-size: 14px;
  color: var(--dark-grey);
}
.statsItemInsight {
  margin-right: 12px;
}

.statsWrapperSmall {
  gap: 8px;
  .statsItem {
    padding: 15px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }
  .statsItemTitle {
    line-height: 1;
    font-size: 20px;
    font-weight: 400;
  }
  .statsItemValue {
    line-height: 1;
    font-size: 36px;
    margin-left: 8px;
  }
  .statsItemInsight {
    margin-right: 0;
    margin-left: 8px;
  }
  .statsItemDescr {
    line-height: 1;
    font-size: 12px;
    color: var(--dark-grey);
    margin-right: 4px;
  }
}
