.imui-clear-button-wrapper {
  position: relative;
  width: 100%;
  height: 0;
}

.imui-clear-button-wrapper-space-right .imui-clear-button {
  margin-right: 30px;
}

.imui-clear-button {
  position: absolute;
  right: 0;
  top: -42px;
  outline: 0;
  border: 0;
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  padding-top: 5px;
  background: white;
  z-index: 1;
  cursor: pointer;
  border-radius: 6px;
  padding-inline: 6px;
  padding-block: 1px;
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 20px;

  svg {
    height: 17px !important;
  }
}
