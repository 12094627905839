.tagsListWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
}

.tagStatContainer {
  display: inline-block;
}

.tagStatItem {
  display: inline-block;
  font-weight: bold;
  color: var(--green);
  margin: 0 2px 0 4px;

  span[role='img'] {
    position: relative;
    top: 1px;
  }
}
