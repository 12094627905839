@import '../Add/GrantAddSteps.module.css';

.fundingInfoWrapper {
  padding-bottom: 25px;
}

.editableOnHover {
  &:hover svg {
    max-height: 18px;
  }

  svg {
    width: 18px !important;
    height: 18px !important;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    margin-left: -18px;
    position: relative;
    top: 3px;
  }

  span {
    display: inline-block;
    padding-right: 21px;
    min-height: 21px;
  }
}
