.dialog {
  width: 550px !important;

  h3 {
    font-size: 20px !important;
    font-weight: 300 !important;
    padding-bottom: 10px !important;
  }
}

.contacts {
  padding-top: 16px;
  width: 1000px;
  padding-right: 30px;
}

.addContactForm {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 -12px 16px !important;

  > div {
    padding: 0 12px !important;
  }
}
