.buttonSettings {
  display: inline-block;
  font-size: 16px !important;
  padding: 10px 12px !important;

  > span {
    cursor: pointer !important;
  }
}

.buttonSettingsBorder {
  border: solid 1px var(--grey) !important;
  color: var(--mid-grey) !important;
  border-radius: 6px !important;
}
