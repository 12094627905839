.searchField {
  flex: 1;
}

.searchHintText {
  line-height: 22px;
}

.reviewSelected {
  background: #fff9c4;
}

.reviewItem {
  border-bottom: 1px solid black;
  padding: 10px;
}

.tagDropdownItem {
  div > div > div:nth-child(1) {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
  }
  div > div > div:nth-child(2) {
    width: 50%;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.searchActions {
  flex: 0 0 auto;
}

.searchActionsButton {
  height: 28px;
  width: 28px;
  padding: 0 !important;
  align-self: center;
  color: #666666 !important;
  margin-right: 0 !important;
}

.searchTaggingsButton {
  height: 28px;
  width: 28px;
  padding: 0 !important;
  align-self: center;
  color: #34aecf !important;
  margin-right: 0 !important;
}

.searchContainerBottom {
  margin-top: 8px;
}

.searchContainerStats {
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
}

.searchStats {
  line-height: 28px;
  font-size: 12px;
  color: var(--soft-purple);
}
