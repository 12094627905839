.tagEditorPdfErrorContainer {
  gap: 20px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.tagEditorPdfErrorWrapper {
  height: 100%;
  width: 100%;
}

.tagEditorPdfErrorCard {
  height: 100%;
}
