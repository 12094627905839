.mappingFormError {
  color: var(--red);
  margin: 0 0 10px;
  text-align: right;
}

.mappingFormErrorContainer {
  margin-bottom: 20px;
}

.mappingFormTextToRight {
  text-align: right;
}

.mappingFormColumnsSection {
  padding-bottom: 300px;
}
