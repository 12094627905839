.reportItemNoWrap {
  white-space: nowrap;
}

.reportItemActions {
  span[role='img'] {
    color: var(--dark);

    &:hover {
      color: var(--dark-blue) !important;
    }
  }
}
