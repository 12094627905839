@import url(./TextField.module.css);
@import url(./base/InputDropdown.module.css);

.imui-chip-input {
  display: flex !important;
  flex-flow: row wrap;

  /* chips parent wrapper */
  & > div:first-of-type {
    order: 1;
    margin-top: 10px;

    /* chips wrapper */
    > div {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -5px;
    }

    /* chip */
    > div > div {
      margin: 5px 0 0 4px;
    }
  }

  & > div:last-of-type,
  & > div:last-of-type > div {
    height: 42px !important;
    margin-bottom: 0 !important;
  }

  &.imui-chip-input-has-hint {
    /* hintText */
    > div:nth-of-type(2) {
      bottom: auto !important;
      top: 9px;
      left: 20px;
      font-weight: 300;
      font-size: 14px;
      z-index: 1;
      pointer-events: none;
    }
  }

  &.imui-chip-input-chips-top {
    /* chips parent wrapper */
    & > div:first-of-type {
      order: 0;
      margin-bottom: 10px;

      /* chips wrapper */
      > div {
        display: flex;
        flex-flow: row wrap;
      }

      /* chip */
      > div > div {
        margin: 0 0 5px 4px;
      }
    }
  }

  &.imui-chip-input-chip-full-width {
    /* chips parent wrapper */
    & > div:first-of-type {
      width: 100%;

      /* chips wrapper */
      > div {
        width: 100%;
        margin: 0;
      }
    }
  }

  input {
    padding-left: 20px !important;
  }
}
