@font-face {
  font-family: 'averta';
  src: url('./avertastd-light-webfont.eot');
  src:
    url('./avertastd-light-webfont.woff2') format('woff2'),
    url('./avertastd-light-webfont.woff') format('woff'),
    url('./avertastd-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'averta';
  src: url('./avertastd-regular-webfont.eot');
  src:
    url('./avertastd-regular-webfont.woff2') format('woff2'),
    url('./avertastd-regular-webfont.woff') format('woff'),
    url('./avertastd-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'averta';
  src: url('./avertastd-regularitalic-webfont.eot');
  src:
    url('./avertastd-regularitalic-webfont.woff2') format('woff2'),
    url('./avertastd-regularitalic-webfont.woff') format('woff'),
    url('./avertastd-regularitalic-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'averta';
  src: url('./avertastd-semibold-webfont.eot');
  src:
    url('./avertastd-semibold-webfont.woff2') format('woff2'),
    url('./avertastd-semibold-webfont.woff') format('woff'),
    url('./avertastd-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
