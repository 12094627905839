.surveyLegalInfo {
  align-items: center;
  padding-bottom: 48px;
}

.surveyLegalInfoSpaceTop {
  padding-top: 64px;
}

.surveyLegalInfoText {
  font-size: 14px;
  color: var(--soft-grey);
  line-height: 1.71;
  letter-spacing: 0.4px;
  padding: 16px;
  text-align: center;
  margin: 0;

  &:last-child {
    margin-top: 1;
  }
}

.logoSmall {
  margin: 48px 0 0px;
}

.surveyLegalInfoButton {
  font-size: 14px !important;
  display: inline-block;
  color: currentColor !important;
}

@media print {
  .surveyLegalInfo {
    padding: 48px 96px 0;
  }

  .surveyLegalInfoText {
    color: var(--mid-grey);
  }
}
