.summary-standalone-container {
  margin: 12px auto;
  gap: 12px;
  justify-content: center;
  max-width: 1060px;
}

.summary-standalone-tile {
  margin: 0;
  flex: 1 0 50%;
}

.summary-standalone-actions {
  justify-content: center;
  max-height: 40px;
}

.summary-standalone-btn-icon {
  line-height: 40px;
  font-size: 36px;
  max-height: 36px;
  max-width: 36px;
  height: 36px;
  width: 36px;
  color: #52cccc;
  margin-left: 20px;
}

.summary-standalone-actions-centered {
  position: relative;
}
.summary-standalone-title {
  margin-bottom: 15px;
}

.summary-standalone-quotes {
  margin: 8px;
  flex: 1 1 50%;
  @media (--large-desktop) {
    max-width: 1060px;
  }
}

.summary-report-totals-container {
  margin: 0;
  flex: 1 0 33%;
  justify-content: center;
  margin: 12px auto;
  gap: 20px;
}
.summary-report-totals-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.summary-report-totals-number {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
}
