.grant-selector-container {
  /* Firefox quirk */
  min-height: 0;
  padding-bottom: 100px;
}

.controls {
  margin-top: 20px;
  align-items: flex-start;
}

.titleAction {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 10px;

  > span {
    margin-right: 10px;
  }
}

.noMargin {
  margin: 0;
}

.tableWrapper {
  max-width: 100%;
  overflow: auto;
  flex: 1 1 auto;
}

.spaceBottom {
  margin-bottom: 20px;
}

.actionButtonsBottom {
  padding-top: 20px;
  border-top: 1px solid #f4f6f9;
}
.staticColumn {
  min-width: 80px;
}
.centered {
  margin: 0 auto;
}
.tableHead {
  th {
    z-index: 3 !important;
  }
}

.lighterText {
  color: #82929f;
  font-weight: 300;
}

.copyTaggingToggle {
  display: flex;
  justify-content: flex-end;
}
