.user-orgs-tile {
  width: 100%;
  position: relative;
}

.user-orgs-tile-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.user-orgs-tile-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  color: #7c90a1;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #6d8396;
    transform: scale(1.2);
  }
}

.user-orgs-tile-info {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
}

.user-orgs-tile-button,
.user-orgs-tile-info {
  margin-bottom: 18px;
}

.user-orgs-tile-info-icon {
  margin-right: 10px;
}

.user-orgs-tile-info-warning {
  color: #e0961d;
}

.user-orgs-tile-info-alarming {
  color: #f82020;
}

.user-orgs-tile-button-container {
  justify-content: center;
}

.user-orgs-tile-button {
  align-self: center;
}

.user-orgs-tile-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-orgs-tile-img {
  margin-bottom: 20px;
}
