.imui-tile-size-s {
  width: 100%;
  max-width: 300px;
  position: relative;
  @media (--desktop) {
    min-width: 300px;
  }
}
.imui-tile-size-m {
  width: 100%;
  max-width: 515px;
  position: relative;
  @media (--desktop) {
    min-width: 400px;
  }
}
.imui-tile-size-l {
  width: 100%;
  max-width: 1059px;
  position: relative;
  @media (--desktop) {
    min-width: 400px;
  }
}
.imui-tile-clickable {
  cursor: pointer;
}
.imui-tile-grow {
  flex-grow: 1;
}
.imui-tile-card-grow {
  height: 100%;
}
