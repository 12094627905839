.helpAppcuesIcon {
  display: inline-block;
}

.helpAppcuesImg {
  position: relative;
  z-index: 2;
  color: var(--mid-grey);
  padding: 0 8px;
  font-size: 16px;
}
