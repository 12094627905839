.tripleItemFixedAction {
  flex: 0 0 50%;
  min-width: 350px;
  margin-left: 10px;
}

.tripleItemSpaceRight {
  margin-right: 10px;
}

.tripleItemBorder {
  &:not(:last-of-type) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--light-grey);
  }
}
