.imui-progress {
  padding: 50px;
  align-items: center;
  justify-content: center;
}
.imui-progress.upload {
  padding: 0;
  width: 20px;
}
.imui-progress.absolute {
  padding: 50px;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 5;
  background: #f4f6f999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
