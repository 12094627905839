.tagItemReviewDivider {
  margin: 5px 0 20px;
}

.tagItemReviewChunk {
  margin-bottom: 20px;
  line-height: 22px;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.tagItemReviewChunkContainer {
  align-items: baseline;

  p {
    margin: 0;
  }
}

.tagItemReviewIcon {
  font-size: 16px;
  color: var(--mid-grey);
  margin-left: 8px;
}
