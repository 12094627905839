.shareConfirmationModal {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.shareConfirmationModalText {
  text-align: center;
  color: var(--soft-purple) !important;
}
