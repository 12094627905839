.tagGroupListHeader {
  padding: 6px;
  border-bottom: 1px solid var(--light-grey);
  flex: 0 0 auto;
}
.tagGroupListHeaderInner {
  gap: 6px;
  max-height: 36px;
  height: 36px;
  align-items: flex-start;
}

.tagGroupListHeaderItem {
  flex: 0 0 auto;

  > * {
    margin-left: 0 !important;
  }

  button {
    padding: 0 !important;
    width: 36px !important;
    height: 36px !important;
    font-size: 16px !important;
    margin-right: 0 !important;
    color: #666666 !important;

    transition: border-color 0.3s;
  }
}

.tagGroupListHeaderItem_grow {
  flex-grow: 1;
  > div {
    width: 100%;
  }
}
.headerItemButtonActive {
  border-color: var(--seafoam) !important;
  span {
    color: var(--seafoam) !important;
  }
}
