.taggingInContextContainer {
  width: 100%;
  max-width: 100%;
}

.taggingInContextContainerInline {
  width: auto;
  display: inline-flex;
  flex: 0 0 auto;
}

.tagHovered {
  cursor: pointer;
}

.tagLabel {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.taggingInContextIcon {
  color: #666666;
  font-size: 13px;
  position: relative;
  top: -2px;
  margin-left: 4px;
  cursor: pointer !important;
}
