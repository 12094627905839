.imui-input-dropdown,
.imui-input-dropdown > div span {
  font-family: averta, Arial, sans-serif !important;
  font-size: 14px !important;
  line-height: 32px !important;
  min-height: 32px !important;
}

.imui-input-dropdown-has-tip {
  position: relative;

  &:after {
    content: 'Type more to show more details';
    position: absolute;
    top: 2px;
    left: 20px;
    line-height: 1.5;
    font-weight: 600;
    color: #82929f;
    font-size: 10px;
  }
}
