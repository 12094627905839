.filterSelectedItem {
  flex: 0 0 auto;
  flex-wrap: nowrap;
  font-size: 12px;
  color: var(--dark-blue);
  margin-right: 16px;
  position: relative;
  min-height: 23px;
  > button {
    max-width: 400px;
    outline: 0;
    padding: 4px 12px;
    cursor: pointer;
  }
}

.filterSelectedItemElement {
  height: 100%;
  background-color: var(--white);

  border-width: 1px 1px 1px 0;
  border-color: var(--seafoam);
  border-style: solid;
}

.filterSelectedItemElementFirst {
  border-left: 1px solid var(--seafoam);
  border-right: 1px dashed var(--seafoam);
}

.filterSelectedItemElementMiddle {
  border-right: 1px dashed var(--seafoam);
  font-style: italic;
  padding: 5px 12px 3px !important;
}

.filterSelectedItemAddContainer {
  display: flex;
  align-items: center;
}

.filterSelectedItemAdd {
  display: inline-block;
  font-size: 16px;
  line-height: 1;

  position: relative;
  top: 1px;
  padding: 2px;
  color: var(--mid-grey);
}

.filterSelectedItemTriangle,
.filterSelectedItemSubTriangle {
  width: 0;
  height: 0;
  min-height: 23px;
  border-style: solid;
  border-left-width: 10px;
}

.filterSelectedItemTriangle {
  border-color: transparent transparent transparent var(--seafoam);
}

.filterSelectedItemSubTriangle {
  border-top-width: inherit;
  border-bottom-width: inherit;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5px;
  z-index: 1;
  border-color: transparent transparent transparent var(--white);
}

.filterSelectedItemTruncatedText {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
