.countryCode {
  white-space: nowrap;
  color: var(--mid-grey);
}

.countryCodeText {
  display: inline-block;
  white-space: normal;
  line-height: 1.07;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: var(--dark);
  font-family: averta, Arial, sans-serif;
}

.countryCodeSelected {
  cursor: pointer;
}

.countryCodeSelectedIcon {
  margin-left: 8px;
  margin-top: 4px;
  cursor: pointer !important;
}

.countryCodeHeader {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.countryCodeHeaderIcon {
  font-size: 16px;
  margin-left: 8px;
}

.countryCodeHeaderText {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.countryCodeHeaderFlag {
  width: 24px !important;
  height: 24px;
  margin: 0 16px 0 12px;
}

.countryCodeFlag {
  font-size: 1.4em;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0.05em);
}
