.row {
  display: flex;
  flex-flow: row wrap;
}

.vertical {
  flex-flow: column;
  /* Firefox quirk */
  min-height: 0;
}

.horizontal {
  width: 100%;
}
