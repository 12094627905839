.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 1em;
  height: 40px;

  li {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    color: #272e42;
    border: solid 1px var(--grey);
    border-right: 0 none;
    vertical-align: middle;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      box-sizing: border-box;
      outline: 0;
      min-width: 40px;
      min-height: 40px;
      line-height: 40px;
      display: block;
      cursor: pointer;
      text-decoration: none;
      color: inherit;
    }
  }

  li:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  li:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: solid 1px var(--grey);
  }

  .previous,
  .next {
    width: 40px;

    a {
      position: relative;
    }

    span {
      font-size: 17px;
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .active {
    background-color: #7c90a1;
    color: #f4f6f9;
  }
}

.paginationCompact {
  margin-left: 0;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.items,
.pages {
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.pagesCompact {
  padding-left: 0;
  padding-right: 0;
  padding-top: 30px;
}

.select {
  width: 110px !important;
}

.itemsText,
.pagesText {
  color: #82929f !important;
  font-size: 12px !important;
  margin-left: 8px !important;
}
