.templateButton {
  font-size: 16px !important;
  display: inline-block;
}

.subTitle {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.7px;
  color: var(--soft-purple);
  margin-bottom: 20px;
}
