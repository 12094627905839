.custom-array-fields {
  transition: 0.3s width ease-in-out;
}

.custom-array-fields-header {
  align-items: baseline;

  h4 {
    margin: 10px 10px 15px 0;
  }
}

.list-items {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.list-item-action {
  list-style: none;
}

.flex-items-end {
  justify-content: flex-end;
}

.custom-array-fields-full-width {
  min-width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 15px;
}

.buttons-separated {
  button + button {
    margin-left: 15px;
  }
}
