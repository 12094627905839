.chartTypeSelector {
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 8px;
  /* radio group */
  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    > div {
      padding: 0 !important;
      /* radio button wrapper */
      > div {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

        /* radio icon wrapper */
        > div {
          width: 32px !important;
          height: 32px !important;
          margin: 0 !important;
        }
      }
    }

    svg {
      opacity: 0 !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      &:first-child {
        opacity: 1 !important;
      }
    }

    label {
      color: #9aaab8 !important;
      width: auto !important;
      font-size: 12px !important;
    }

    input:checked + div {
      svg {
        opacity: 1 !important;
        &:first-child {
          opacity: 0 !important;
        }
      }
      label {
        color: #52cccc !important;
      }
    }

    input:disabled + div {
      svg:first-child {
        opacity: 0.4 !important;
        svg:first-child + svg {
          opacity: 0;
        }
      }
      label {
        color: #9aaab8 !important;
        opacity: 0.4 !important;
      }
    }
  }
}
/* EOF .chartTypesWrapper */
