.questionAvatar {
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark);
  line-height: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  [class^='icon'] {
    margin-right: 8px;
  }
  &:hover {
    color: var(--ocean);
  }
  &:last-child {
    margin-bottom: 0px;
  }
}
