.createTagCategoryReviewItem {
  margin-bottom: 20px;
  line-height: 22px;
  font-size: 14px;
  letter-spacing: 0.25px;
  align-items: baseline;

  p {
    margin: 0;
  }
}

.createTagCategoryReviewIcon {
  font-size: 16px;
  color: var(--mid-grey);
  margin-left: 8px;
}

.createTagCategoryReviewTitle {
  margin-bottom: 24px;
}

.createTagCategoryReviewMetatags {
  display: flex;

  > div + div {
    margin-left: 8px;
  }
}
