.popoverLightHeader {
  padding: 0;
}

.popoverLightContainer {
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  color: var(--dark);
  align-items: center;
}

.popoverLightContainerNoPadding {
  padding: 0;
}

.popoverLightContainerIcon {
  padding-right: 15px;
  font-size: 16px;
  position: relative;
  top: 1px;
}
