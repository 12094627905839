.treeNode {
  max-width: calc(100vw - 200px);
  list-style: none;
  position: relative;
  z-index: 1;
  padding-left: 40px;
  padding-bottom: 8px;

  &:last-of-type {
    &:after {
      content: '';
      height: 100%;
      width: 1px;
      display: inline-block;
      position: absolute;
      left: 8px;
      top: 0;
      background-color: var(--white);
    }

    .treeNodeContent:after {
      content: '';
      height: calc(50% + 1px);
      width: 1px;
      display: inline-block;
      position: absolute;
      left: -33px;
      top: -1px;
      z-index: 3;
      background-color: var(--grey);
    }
  }
}

.treeNodeContent {
  display: inline-flex;
  position: relative;
  flex-wrap: nowrap;

  font-size: 12px;
  letter-spacing: 0.5px;

  background: var(--white);
  border: 1px solid #99aab8;
  border-radius: 8px;
  height: auto;
  padding: 4px 8px;
  span[role='img'] {
    font-size: 16px;
  }

  &:before {
    content: '';
    width: 32px;
    height: 1px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -33px;
    transform: translateY(-50%);
    background-color: var(--grey);
  }
}

.treeNodeContentTag {
  min-height: 21px;
  max-height: 21px;
  overflow: hidden;
}
.treeNodeContentLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 11px;
  letter-spacing: -0.25px;
}

.treeNodeContentChild {
  border-color: var(--mid-grey);
  padding: 0;

  &:before {
    background-image: linear-gradient(
      90deg,
      var(--grey),
      var(--grey) 75%,
      transparent 75%,
      transparent 100%
    );
    background-size: 8px 5px;
    background-color: transparent;
  }
}

.treeNodeOuterIcon {
  position: absolute;
  top: 50%;
  left: -40px;
  z-index: 4;
  transform: translateY(-50%);
  color: var(--soft-purple);
  background-color: var(--white);
}

.treeNodeEditIcon {
  margin-left: 14px;
  color: var(--dark-grey);
}

.treeNode:hover .treeNodeSeparator {
  opacity: 1;
}
.tree li + li.treeNode {
  margin-top: 32px;
}

.treeNodeSeparator {
  display: block;
  opacity: 0;
  position: absolute;
  bottom: -16px;
  left: 8px;
  right: -32px;
  text-align: right;

  &:before {
    content: '';
    height: 1px;
    position: absolute;
    left: 0px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    background-image: linear-gradient(
      90deg,
      var(--grey),
      var(--grey) 75%,
      transparent 75%,
      transparent 100%
    );
    background-size: 8px 5px;
  }
}

.treeNodeSeparatorIcon {
  position: relative;
  z-index: 9;
  color: var(--soft-purple);
}
.metatagTagsList {
  position: absolute;
  display: flex;
  left: 100%;
  margin: 0 -2px;
  margin-left: 24px;
  height: 27px;
  width: auto;
  white-space: nowrap;
  > * {
    margin: 2px;
  }
}
