.imui-back-button {
  color: #666666;
  cursor: pointer;

  svg {
    stroke: #666666;
    transform: rotate(180deg);
    width: 48px;
    height: 48px;
    margin-bottom: -4px;
  }
}

@media print {
  .imui-back-button {
    display: none;
  }
}
