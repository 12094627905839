.imui-more-add-all-item-positive:hover {
  color: #52cccc !important;
}

.imui-more-add-all-item-alert:hover {
  color: #ea695e !important;
}

.imui-more-add-all-item-disabled {
  pointer-events: none;
  cursor: not-allowed;
}
