.selectorButtonText {
  color: var(--soft-purple) !important;
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: 1.33 !important;
  letter-spacing: 0.5px !important;

  &:hover {
    color: #555c72 !important;
  }
}

.printVisible {
  display: none;

  @media print {
    display: block;
  }
}

.printTextField {
  padding: 6px 20px;
  color: rgb(34, 30, 32);
  border: 1px solid rgb(232, 234, 237);
  margin-bottom: -4px;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 14px;
  line-height: 24px;
  white-space: pre-wrap;
}

.printTextFieldEmpty {
  background-color: var(--greyish);
}

.printTextFieldSingle {
  min-height: 42px;
}

.printTextFieldMultiline {
  min-height: 200px;
}

.fieldIcon {
  margin-bottom: 14px;
  font-size: 16px;
}
