.wrapper {
  position: absolute;
  top: 10px;
  bottom: 0px;
  right: 0;
  width: 10px;
  border-left: 2px solid var(--light-grey);
  background-color: #ffffff60;
}

.mark {
  position: absolute;
  width: 100%;
  left: 0;
  height: 2px;
}
