.imui-table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;

  thead {
    &.imui-table-head-transparent th {
      background-color: transparent !important;
      border-top: 1px solid var(--light-grey);
      border-bottom: 1px solid var(--light-grey);
    }

    &.imui-table-head-bold th {
      font-weight: bold !important;
    }

    td {
      background-color: #fff;
    }

    th,
    td {
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;

      &:last-child {
        border-right: none;
      }
    }
  }

  tbody:last-child .imui-table-row:last-child {
    border-bottom: none;
  }

  &.imui-table-layout-auto {
    table-layout: auto;
  }

  &.imui-table-layout-fixed {
    table-layout: fixed;
  }

  .imui-table-row {
    &.imui-table-row-no-border {
      border-bottom: none;
    }

    &.imui-table-row-border-dashed {
      border-bottom-style: dashed;
    }

    &:not(&.imui-table-row-footer)
      + &.imui-table-row-footer
      + &.imui-table-row-footer {
      > * {
        padding-top: 16px;
      }
    }

    &.imui-table-row-footer {
      border-top: 2px solid var(--grey);
      border-bottom: 2px solid var(--grey);

      + .imui-table-row-footer {
        border: none;

        > * {
          padding: 6px 10px;
          color: var(--soft-purple);
        }
      }
      > * {
        &:first-child {
          font-weight: bold;
          text-align: right;
          border-right: 1px solid var(--light-grey);
        }
      }
    }

    .imui-table-header-cell {
      background-color: #f0f2f5;
      padding: 16px 10px;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.17;
      letter-spacing: 0.5px;
      text-align: left;
      color: #262a2d;
      white-space: pre-line;
    }
  }

  tbody {
    .imui-table-row .imui-table-header-cell {
      border-right: 1px solid #f0f2f5;
    }
  }

  .imui-table-cell-nowrap {
    white-space: nowrap;
  }

  .imui-table-row {
    border-bottom: 1px solid var(--light-grey);

    .imui-table-cell-sub-header {
      background-color: #f0f2f5;
    }

    &:hover {
      background-color: #fcfcfd;

      .imui-table-cell-sub-header {
        background-color: #fcfcfd;
      }
    }

    &.imui-table-row-clickable {
      cursor: pointer;
    }

    .imui-table-cell {
      padding: 16px 10px;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.5px;
      text-align: left;
      color: #262a2d;

      border-right: 1px solid #fff;

      &:last-child {
        border-right: none;
      }

      &.imui-table-cell-actions {
        padding: 0px;
        text-align: center;

        svg {
          vertical-align: bottom;
        }

        [class^='icon'] {
          margin-right: 16px;
          font-size: 16px;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            color: var(--seafoam);
          }
        }
      }
      &.imui-table-cell-actions.imui-table-cell-textAlign-right {
        text-align: right;
      }

      &.imui-table-cell-icons {
        white-space: nowrap;
        padding-top: 0px;
        padding-bottom: 0px;

        svg {
          margin-left: 10px;
          width: 22px !important;
          height: 22px !important;
          cursor: pointer;

          &:first-child {
            margin: 0;
          }
        }
      }

      &.imui-table-cell-dim {
        color: #9aaab8;
      }

      &.imui-table-cell-clickable {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      &.imui-table-cell-numerical {
        text-align: right;
        font-family: monospace;
      }
    }
  }

  .imui-table-header-cell,
  .imui-table-cell {
    small {
      opacity: 0.8;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 0.7px;
    }

    &.imui-table-cell-vAlign-baseline {
      vertical-align: baseline;
    }
    &.imui-table-cell-vAlign-sub {
      vertical-align: sub;
    }
    &.imui-table-cell-vAlign-super {
      vertical-align: super;
    }
    &.imui-table-cell-vAlign-text-top {
      vertical-align: text-top;
    }
    &.imui-table-cell-vAlign-text-bottom {
      vertical-align: text-bottom;
    }
    &.imui-table-cell-vAlign-middle {
      vertical-align: middle;
    }
    &.imui-table-cell-vAlign-top {
      vertical-align: top;
    }
    &.imui-table-cell-vAlign-bottom {
      vertical-align: bottom;
    }

    &.imui-table-cell-textAlign-center {
      text-align: center;
    }
    &.imui-table-cell-textAlign-left {
      text-align: left;
    }
    &.imui-table-cell-textAlign-right {
      text-align: right;
    }
    &.imui-table-cell-textAlign-justify {
      text-align: justify;
    }
  }

  .imui-table-expandable-row {
    border-bottom: none;

    &.imui-table-expandable-row-visible {
      border-bottom: 1px dashed var(--light-grey);
      border-top: 1px dashed var(--light-grey);
    }

    & > .imui-table-expandable-row-cell {
      padding: 0 !important;
    }

    .imui-table-expandable-row-content {
      max-height: 0;
      display: block;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
    }
  }

  .imui-table-expand-icon {
    display: inline-block;
    font-size: 16px;
    cursor: pointer !important;
    margin-right: 8px;

    transition: all 0.2s;
    transform: rotate(0deg);

    &.imui-table-expand-icon-less {
      transform: rotate(180deg);
    }
  }

  .imui-table-header-cell-sort-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover .imui-table-sort-icon {
      color: #b7babf;
    }

    &:hover
      .imui-table-sort-icon:not(.imui-table-sort-icon-active)
      + .imui-table-sort-icon:not(.imui-table-sort-icon-active) {
      color: #d2d4d7;
    }

    .imui-table-sort-icons {
      display: flex;
      flex-flow: column wrap;
      margin-left: 10px;
    }

    .imui-table-sort-icon {
      font-size: 8px;
      cursor: pointer !important;

      color: #d2d4d7;
      transition: color 0.2s;
    }

    .imui-table-sort-icon.imui-table-sort-icon-active {
      color: #272e42;
    }

    .imui-table-sort-icon + .imui-table-sort-icon {
      margin-top: 1px;
    }
  }

  &.imui-table-compact {
    .imui-table-header-cell,
    .imui-table-cell {
      padding: 8px 6px;
    }
  }

  &.imui-table-sticky {
    thead th,
    thead td {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  &.imui-table-sticky-visible thead th {
    &:before {
      content: '';
      width: 100%;
      height: 2px;
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: -2px;
      background-color: var(--dark);
      opacity: 0.2;
    }
  }

  &.imui-table-sticky-column-first tr > th:first-of-type,
  &.imui-table-sticky-column-first tr > td:first-of-type {
    position: sticky;
    left: 0;
  }

  &.imui-table-sticky-column-first tr > th:first-of-type {
    z-index: 2;
  }

  &.imui-table-sticky-column-first tr > td:first-of-type {
    z-index: 1;
    background-color: var(--white);
    /* Firefox fix for missing border */
    background-clip: padding-box;
  }

  &.imui-table-sticky-column-first tr:hover > td:first-of-type {
    background-color: #fcfcfd;
  }

  &.imui-table-sticky-column-first-visible tr > th:first-of-type,
  &.imui-table-sticky-column-first-visible tr > td:first-of-type {
    &:after {
      content: '';
      height: 100%;
      width: 2px;
      display: inline-block;
      position: absolute;
      top: 0;
      right: -2px;
      background-color: var(--dark);
      opacity: 0.2;
    }
  }
}

.tableWrapper {
  position: relative;
}

.progressOverlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  padding-top: 50px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imui-table-row:not(.imui-table-row-footer)
  + .imui-table-row-footer
  + .imui-table-row-footer
  > * {
  padding-top: 16px !important;
}
