.imui-form-field {
  padding-bottom: 8px;
}

.imui-form-field-extra-spacing {
  padding-bottom: 24px;
}

.imui-form-field-inlineBlock {
  display: inline-block;
}
