.mergeTagForm {
  width: 100%;
}

.mergeTagFormFooter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.mergeTagFormDivider {
  margin: 5px 0 20px;
}

.mergeTagSelectorPopover:not(.imui-popover-custom-width) {
  max-height: 600px !important;
}

.tagSelectorButton {
  max-width: 475px;
}

.mergeTagFormSelectTagsToMerge {
  font-size: 16px;
  font-weight: normal;
  display: block;
  white-space: normal;
  text-align: left;
}

.mergeTagFormTagTitle {
  color: black;
  font-weight: bold;
}

.editTagMergeIconWrapper {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto;
}

.editTagMergeIconSuccess {
  background-color: #e8ffe5;
}

.editTagMergeIconWarning {
  background-color: var(--lemon-tart);
}

.mergeOptions {
  padding: 20px 0;
}

.mergeOptionTooltip {
  padding: 12px;
  width: 350px;
}
